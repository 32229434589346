import Fuse from 'fuse.js';
import _ from 'lodash';

const defaultState = {
	productsById: {},
	productBySku: new Fuse([], {
		shouldSort: true,
		threshold: 0.2,
		location: 0,
		distance: 100,
		maxPatternLength: 32,
		minMatchCharLength: 1,
		keys: [
			'sku',
		],
	}),
	productByName: new Fuse([], {
		shouldSort: true,
		threshold: 0.6,
		location: 0,
		distance: 100,
		maxPatternLength: 32,
		minMatchCharLength: 1,
		keys: [
			'name',
		],
	}),
	productByShortName: new Fuse([], {
		shouldSort: true,
		threshold: 0.6,
		location: 0,
		distance: 100,
		maxPatternLength: 32,
		minMatchCharLength: 1,
		keys: [
			'shortName',
		],
	}),
	productByEan: new Fuse([], {
		threshold: 0,
		keys: [
			'barcodes.barcode',
		],
	}),
};

export default function search(state = defaultState, action) {
	switch (action.type) {
	case 'FETCH_PRODUCTS_SUCCESS': {
		const newState = {
			...state,
			productsById: _.keyBy(action.payload.data, 'id'),
			productsBySku: _.keyBy(action.payload.data, 'sku'),
		};

		const products = _.values(newState.productsById).map(product => ({
			...product,
			sku: `${product.sku}`,
		}));

		state.productBySku.setCollection(products);
		state.productByName.setCollection(products);
		state.productByShortName.setCollection(products);
		state.productByEan.setCollection(products);
		return newState;
	}
	case 'CREATE_PRODUCT_SUCCESS': {
		const newState = {
			...state,
			productsById: {
				..._.omit(state.productsById, [`import_${action.payload.data.sku}`]),
				[action.payload.data.id]: action.payload.data,
			},
			productsBySku: {
				...state.productsBySku,
				[action.payload.data.sku]: action.payload.data,
			},
		};

		const products = _.values(newState.productsById).map(product => ({
			...product,
			sku: `${product.sku}`,
		}));

		state.productBySku.setCollection(products);
		state.productByName.setCollection(products);
		state.productByShortName.setCollection(products);
		state.productByEan.setCollection(products);
		return newState;
	}
	case 'CALCULATION_CREATE_ITEM_SUCCESS': {
		const newState = {
			...state,
			productsById: {
				...state.productsById,
				[action.payload.data.product.id]: {
					...state.productsById[action.payload.data.product.id],
					calculationItems: [
						...state.productsById[action.payload.data.product.id].calculationItems,
						action.payload.data,
					],
				},
			},
		};

		const products = _.values(newState.productsById).map(product => ({
			...product,
			sku: `${product.sku}`,
		}));

		state.productBySku.setCollection(products);
		state.productByName.setCollection(products);
		state.productByShortName.setCollection(products);
		state.productByEan.setCollection(products);
		return newState;
	}
	case 'PRODUCTS_IMPORT_SUCCESS': {
		const newState = {
			...state,
			productsById: {
				...state.productsById,
				..._.keyBy(action.payload.data
					.filter(product => !state.productsBySku[product.sku])
					.map(product => ({
						...product,
						import: true,
						id: `import_${product.sku}`,
					})), 'id'),

			},
		};

		const products = _.values(newState.productsById).map(product => ({
			...product,
			sku: `${product.sku}`,
		}));

		state.productBySku.setCollection(products);
		state.productByName.setCollection(products);
		state.productByShortName.setCollection(products);
		state.productByEan.setCollection(products);
		return newState;
	}
	default: {
		return state;
	}
	}
}
