import React, { Component, Fragment } from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import floor from 'lodash/floor';
import {
	Input, Button, Table, Select, DatePicker, Spin, Icon, Popconfirm, Row, Col, notification, Progress, Upload
} from 'antd';
import print from 'print-html-element';
import nl2br from 'react-newline-to-break';

import moment from 'moment';
import diacritics from 'diacritics';

import {
	fetchSales, createSale, deleteSale, updateSale, processSale, createSaleItem,
} from '../../../../actions/sales';
import { updateSaleItem, deleteSaleItem } from '../../../../actions/saleItems';
import { fetchStores } from '../../../../actions/stores';
import { fetchDistributors } from '../../../../actions/distributors';
import { fetchInvoices } from '../../../../actions/invoices';
import { fetchSoldItems, fetchTotals } from '../../../../actions/ecr';
import CrudPage from '../../../../components/CrudPage';
import CreateSaleItemsModal from './CreateSaleItems';
import ProcessSale from './ProcessSale';

import {
	createProduct, fetchProducts, productsImport,
} from '../../../../actions/products';

import numberFormat from '../../../../lib/numberFormat';

class Sales extends Component {
	static propTypes = {
		fetchInvoices: PropTypes.func.isRequired,
		updateSaleItem: PropTypes.func.isRequired,
		createSaleItem: PropTypes.func.isRequired,
		deleteSaleItem: PropTypes.func.isRequired,
		fetchStores: PropTypes.func.isRequired,
		processSale: PropTypes.func.isRequired,
		isLoading: PropTypes.bool.isRequired,
		isLoadingDistributors: PropTypes.bool.isRequired,
		isLoadingStores: PropTypes.bool.isRequired,
		isLoadingInvoices: PropTypes.bool.isRequired,
		stores: PropTypes.arrayOf(
			PropTypes.shape({})
		).isRequired,
		invoices: PropTypes.arrayOf(
			PropTypes.shape({})
		).isRequired,
		distributors: PropTypes.arrayOf(
			PropTypes.shape({})
		).isRequired,
	}

	state = {
		createVisible: false,
		processVisible: false,
		saleItem: null,
	}

	calcRefs = {}

	componentDidMount() {
		this.props.fetchInvoices();
		this.props.fetchStores();
		this.props.fetchDistributors();
	}

	editRender(property, editComponent, render) {
		return (text, record) => (render ? render(text, record) : text)
		// }

		// return cloneElement(editComponent, {
		// 	onChange: (event) => {
		// 		const newRecord = {
		// 			...record,
		// 		};
		// 		if (event.currentTarget) {
		// 			return this.props.updateSaleItem(_.set(newRecord, property, event.currentTarget.value));
		// 		}

		// 		return this.props.updateSaleItem(_.set(newRecord, property, event));
		// 	},
		// 	defaultValue: text,
		// });
		;
	}

	expandedRowRender(data) {
		let totalOldValue = 0;
		let totalNewValue = 0;

		data.saleItems.forEach((item) => {
			totalOldValue += (item.oldPrice * item.quantity);
			totalNewValue += (item.price * item.quantity);
		});
		const totalDiff = totalNewValue - totalOldValue;
		return (
			<div
				className="bo-sale"
				ref={(el) => { this.calcRefs[data.id] = el; }}
			>
				<Table

					footer={() => [
						<Button
							onClick={() => this.setState({ createVisible: true, sale: data })}
							icon="plus"
							style={{ marginRight: 8 }}
							key="addButton"
						>
								Dodaj nove stavke
						</Button>,
						<Upload
							showUploadList={false}
							beforeUpload={async (file) => {
								const contents = await file.text();

								notification.info({
									key: 'salesImport',
									message: 'Uvoz je u toku',
									description: 'Uvoze se podaci sa fiskalne kase',
									duration: 0,
								});

								try {
									notification.info({
										key: 'salesImport',
										message: 'Osvežavanje baze proizvoda',
										description: <span><Progress percent={0} status="active" /></span>,
										duration: 0,
									});
									await this.props.fetchProducts();
									notification.info({
										key: 'salesImport',
										message: 'Uvoz proizvoda je u toku',
										description: <span><Progress percent={0} status="active" /></span>,
										duration: 0,
									});
									await this.props.productsImport();

									const keys = Object.keys(this.props.productsById);

									for (let i = 0; i < keys.length; i += 1) {
										const product = this.props.productsById[keys[i]];

										if (product.import) {
											notification.info({
												key: 'salesImport',
												message: 'Uvoz proizvoda je u toku',
												description: <span><Progress percent={Math.floor((i / keys.length) * 100)} status="active" /></span>,
												duration: 0,
											});
											await this.props.createProduct({
												sku: product.sku,
												name: product.name,
												shortName: diacritics.remove(product.shortName),
												barcodes: product.barcodes
													.map((barcode => barcode.barcode)),
												tax: product.tax,
											});
										}
									}

									const sold = JSON.parse(contents);

									notification.info({
										key: 'salesImport',
										message: 'Uvoz je u toku',
										description: 'Kreiranje prodaje',
										duration: 0,
									});
									const sale = data;
									for (let i = 0; i < sold.length; i += 1) {
										const item = sold[i];
										notification.info({
											key: 'salesImport',
											message: 'Uvoz je u toku',
											description: <span><Progress percent={Math.floor((i / sold.length) * 100)} status="active" /></span>,
											duration: 0,
										});
										await this.props.createSaleItem(sale.id, {
											saleId: sale.id,
											sku: item.plu,
											sold: item.sold,
											total: item.total,
										});
									}
									notification.success({
										key: 'salesImport',
										message: 'Uvoz je završen',
										description: <span><Progress percent={100} /></span>,
										duration: 5,
									});
								} catch (error) {
									notification.error({
										key: 'salesImport',
										message: 'Greška',
										description: 'Greška prilikom uvoza iz fajla.',
										duration: 30,
									});
								}

								return false;
							}}
						>
							<Button
								icon="upload"
								style={{ marginRight: 8 }}
								key="importButton"
							>
									Uvezi iz fajla
							</Button>
						</Upload>,
					]
					}
					pagination={false}
					size="middle"
					bordered
					rowKey={record => record.id || record.tmpId}
					dataSource={data.saleItems}
					columns={[
						{
							title: '#',
							key: 'index',
							width: '80px',
							render(text, record, index) {
								return index + 1;
							},
						},
						{
							title: 'Šifra',
							key: 'product.sku',
							dataIndex: 'product.sku',
							width: '70px',

						},
						{
							title: 'Naziv',
							key: 'product.name',
							dataIndex: 'product.name',
						},
						{
							title: 'Cena bez PDV-a',
							key: 'netPrice',
							dataIndex: 'netPrice',
							width: '100px',
							render: (text, record) => numberFormat(record.netPrice / record.quantity),
						},
						{
							title: 'PDV',
							key: 'tax',
							dataIndex: 'tax',
							width: '100px',
							render: (text, record) => numberFormat(record.tax / record.quantity),
						},
						{
							title: 'Cena',
							key: 'grossPrice',
							dataIndex: 'grossPrice',
							width: '100px',
							render: (text, record) => numberFormat(record.grossPrice / record.quantity),
						},
						{
							title: 'Količina',
							key: 'quantity',
							dataIndex: 'quantity',
							width: '70px',
						},
						{
							title: 'Vrednost',
							key: 'value',
							width: '140px',
							render: (text, record) => numberFormat(record.grossPrice),
						},
						{
							title: 'Akcije',
							key: 'actions',
							className: 'bo-column-actions',
							width: '140px',
							render: (text, record) => (
								<Button.Group>
									<Button
										onClick={() => {
											this.setState({
												saleItem: record,
												createVisible: true,
												sale: data,
											});
										}}
									>
									Izmeni
									</Button>
									<Popconfirm
										placement="topRight"
										title="Da li ste sigurni da želite da obrišete ovu stavku?"
										onConfirm={() => {
											this.props.deleteSaleItem(record);
										}}
										okText="Da"
										cancelText="Ne"
									>
										<Button icon="delete" />
									</Popconfirm>
								</Button.Group>
							),
						},

					]}
				/>
				<Row
					className="bo-sale-summary-container"
					gutter={8}
					style={{ marginBottom: -1 }}
				>
					<Col
						className="bo-sale-summary-col1"
						span="6"
					>
						<Table
							className="bo-sale-summary"
							rowClassName={record => record.className || ''}
							pagination={false}
							dataSource={[
								{
									title: 'Prodajna vrednost bez PDV-a',
									amount: numberFormat(0),
									key: 'amount',
								},
								{
									title: 'PDV',
									amount: numberFormat(0),
									key: 'tax',
								},
								{
									title: 'Plaćeno (Gotovina)',
									amount: numberFormat(data.paidCash),
									key: 'paidCash',
								},
								{
									title: 'Plaćeno (Kartica)',
									amount: numberFormat(data.paidCard),
									key: 'paidCard',
								},
								{
									title: 'Plaćeno (Ček)',
									amount: numberFormat(data.paidCheck),
									key: 'paidCheck',
								},
								{
									title: 'Plaćeno (Ukupno)',
									amount: numberFormat(data.paidTotal),
									key: 'paidTotal',
								},

							]}
							bordered
							size="middle"
							showHeader={false}
							columns={[
								{
									dataIndex: 'title',
								},
								{
									dataIndex: 'amount',
								},
							]}
						/>
					</Col>
					<Col
						className="bo-sale-summary-col2"
						span="18"
					/>
				</Row>
			</div>
		);
	}

	render() {
		if (this.props.isLoading) {
			return <Spin />;
		}

		return (
			<CrudPage
				tableProps={{
					expandedRowRender: data => this.expandedRowRender(data),
					className: 'bo-nested-table',
				}}
				fetchAction={fetchSales}
				createAction={createSale}
				deleteAction={deleteSale}
				updateAction={updateSale}
				headerContents={(
					<span style={{ marginLeft: 8 }}>
						<Button
							onClick={async () => {
								notification.info({
									key: 'salesImport',
									message: 'Uvoz je u toku',
									description: 'Uvoze se podaci sa fiskalne kase',
									duration: 0,
								});

								try {
									notification.info({
										key: 'salesImport',
										message: 'Osvežavanje baze proizvoda',
										description: <span><Progress percent={0} status="active" /></span>,
										duration: 0,
									});
									await this.props.fetchProducts();
									notification.info({
										key: 'salesImport',
										message: 'Uvoz proizvoda je u toku',
										description: <span><Progress percent={0} status="active" /></span>,
										duration: 0,
									});
									await this.props.productsImport();

									const keys = Object.keys(this.props.productsById);

									for (let i = 0; i < keys.length; i += 1) {
										const product = this.props.productsById[keys[i]];

										if (product.import) {
											notification.info({
												key: 'salesImport',
												message: 'Uvoz proizvoda je u toku',
												description: <span><Progress percent={Math.floor((i / keys.length) * 100)} status="active" /></span>,
												duration: 0,
											});
											await this.props.createProduct({
												sku: product.sku,
												name: product.name,
												shortName: diacritics.remove(product.shortName),
												barcodes: product.barcodes
													.map((barcode => barcode.barcode)),
												tax: product.tax,
											});
										}
									}

									const totalsResponse = await this.props.fetchTotals();
									const totals = totalsResponse.payload.data;
									const soldResponse = await this.props.fetchSoldItems();
									const sold = soldResponse.payload.data;

									notification.info({
										key: 'salesImport',
										message: 'Uvoz je u toku',
										description: 'Kreiranje prodaje',
										duration: 0,
									});
									const sale = await this.props.createSale({
										timestamp: moment().toISOString(),
										import: true,
										storeId: 2, // Make a global store chooser
										paidCash: totals.cash,
										paidCard: totals.card,
										paidCheck: totals.check,
										paidTotal: totals.cash + totals.card + totals.check,
									});
									for (let i = 0; i < sold.length; i += 1) {
										const item = sold[i];
										notification.info({
											key: 'salesImport',
											message: 'Uvoz je u toku',
											description: <span><Progress percent={Math.floor((i / sold.length) * 100)} status="active" /></span>,
											duration: 0,
										});
										await this.props.createSaleItem(sale.payload.data.id, {
											saleId: sale.payload.data.id,
											sku: item.plu,
											sold: item.sold,
											total: item.total,
										});
									}
									notification.success({
										key: 'salesImport',
										message: 'Uvoz je završen',
										description: <span><Progress percent={100} /></span>,
										duration: 5,
									});
								} catch (error) {
									notification.error({
										key: 'salesImport',
										message: 'Greška',
										description: 'Greška prilikom povezivanja sa fiskalnom kasom. Proverite da li se kasa nalazi u režimu "PC VEZA"',
										duration: 30,
									});
								}

								// this.props.fetchSoldItems()
								//	.then(items => console.log(items));
							}}
						>
							Uvezi sa fiskalne kase
						</Button>
					</span>
				)}
				useMap
				createTitle="Dodaj Prodaju"
				editTitle="Izmeni Prodaju"
				addButtonText="Dodaj Prodaju"
				deleteButtonPrompt="Da li ste sigurni da želite da obrišete ovu prodaju?"
				stateProperty="sales"
				columns={[
					{
						title: '#',
						key: 'id',
						dataIndex: 'id',
						width: '80px',
					},
					{
						title: 'Prodavnica',
						dataIndex: 'store.name',
						key: 'store.name',
						sorter: (a, b) => {
							if (a.store.name < b.store.name) return -1;
							if (a.store.name > b.store.name) return 1;
							return 0;
						},
						filters: this.props.stores.map(store => ({
							text: store.name,
							value: store.id,
						})),
						filterMultiple: true,
						onFilter: (value, record) => `${record.storeId}` === value,
					},
					{
						title: 'Datum i vreme prodaje',
						dataIndex: 'timestamp',
						key: 'timestamp',
						render: text => moment(text).format('LLLL'),
					},
					{
						title: 'Proknjižena',
						dataIndex: 'processed',
						render: value => <span className={value ? 'bo-paid' : 'bo-not-paid'}>{value ? <Icon type="check" /> : <Icon type="close" />}</span>,
					},
				]}
				convert={{
					timestamp: moment,
					storeId: storeId => `${storeId}`,
				}}
				extraButtons={(text, record) => [
					<Button
						key="process"
						onClick={() => {
							this.setState({
								processVisible: true,
								sale: record,
							});
						}}
						icon="book"
					/>,
				]}
				properties={[
					{
						label: 'Broj prodaje',
						property: 'number',
						rules: [],
						component: <Input />,
					},
					{
						label: 'Prodavnica',
						property: 'storeId',
						rules: [{
							required: true, message: 'Prodavnica je obavezna',
						}],
						component: <Select
							showSearch
							filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						>
							{this.props.isLoadingStores ? <Select.Option className="bo-select-loading" key="loading" disabled><Spin /></Select.Option> : null}
							{this.props.stores.map(
								store => <Select.Option key={store.id} value={`${store.id}`}>{store.name}</Select.Option>
							)
							}
						</Select>,
					},
					{
						label: 'Datum i vreme prodaje',
						property: 'timestamp',
						rules: [{
							required: true, message: 'Datum prodaje je obavezan',
						}],
						component: <DatePicker
							showTime
							format="YYYY-MM-DD HH:mm:ss"
							placeholder="Izaberi Vreme"
						/>,
					},
				]}
			>
				<CreateSaleItemsModal
					visible={this.state.createVisible}
					sale={this.state.sale}
					entity={this.state.saleItem}
					handleSave={() => {
						this.setState({ createVisible: false, saleItem: null });
					}}
					handleCancel={() => {
						this.setState({ createVisible: false, saleItem: null });
					}}
					convert={{}}
				/>
				<ProcessSale
					visible={this.state.processVisible}
					entity={this.state.sale}
				/>
			</CrudPage>
		);
	}
}

function mapStateToProps(state) {
	return {
		isLoading: state.stores.isFetching || state.invoices.isFetching || state.distributors.isFetching,
		isLoadingStores: state.stores.isFetching,
		isLoadingInvoices: state.invoices.isFetching,
		isLoadingDistributors: state.distributors.isFetching,
		invoices: state.invoices.invoices,
		stores: state.stores.stores,
		distributors: state.distributors.distributors,
		productsById: state.search.productsById,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		fetchProducts,
		productsImport,
		createProduct,
		fetchStores,
		fetchDistributors,
		fetchInvoices,
		updateSaleItem,
		createSaleItem,
		deleteSaleItem,
		processSale,
		fetchSoldItems,
		fetchTotals,
		createSale,
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Sales);
