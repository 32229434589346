// @flow
import React, { Component } from 'react';

import {
	Modal, Form, Input, Divider,
} from 'antd';

type Props = {
	amount: Number,
	form: any,
	visible: boolean,
}

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 12 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 12 },
	},
};

@Form.create({
	name: 'payment',
	onFieldsChange: (props, fields) => {
		props.sale.updatePayment(
			fields.cash ? parseFloat(fields.cash.value) : null,
			fields.check ? parseFloat(fields.check.value) : null,
			fields.card ? parseFloat(fields.card.value) : null,
		);
	},
})
export default class PaymentModal extends Component<Props> {
	static defaultProps = {
		visible: false,
	}

	componentDidMount = () => {
		// this.cashInput.focus();
	}

	render() {
		const {
			form: {
				getFieldDecorator,
			},
			sale: { payment },
			sale,
			amount,
			visible,
		} = this.props;

		return (
			<Modal
				width={400}
				title="Plaćanje"
				visible={visible}
				onOk={() => {
					sale.completeSale();
				}}
			>
				<Form
					{...formItemLayout}
				>
					<Form.Item
						label="Ukupno za uplatu"
					>
						{
							<Input
								value={`${amount}`}
								readOnly
							/>
						}
					</Form.Item>
					<Divider />
					<Form.Item
						label="Gotovina"
					>
						{
							getFieldDecorator('cash', {
								initialValue: payment.cash,
							})(
								<Input
									ref={(input) => { input.focus(); input.select(); }}
									allowClear
								/>
							)
						}
					</Form.Item>
					<Form.Item
						label="Ček"
					>
						{
							getFieldDecorator('check', {
								initialValue: payment.check,
							})(
								<Input
									allowClear
								/>
							)
						}
					</Form.Item>
					<Form.Item
						label="Kartica"
					>
						{
							getFieldDecorator('card', {
								initialValue: payment.card,
							})(
								<Input
									allowClear
								/>
							)
						}
					</Form.Item>
					<Divider />
					<Form.Item
						label="Povraćaj"
					>
						{
							getFieldDecorator('change', {
								initialValue: payment.change,
							})(
								<Input
									readOnly
								/>
							)
						}
					</Form.Item>
				</Form>
			</Modal>
		);
	}
}
