
import { keyBy } from 'lodash';
import crudReducer from './crudReducer';

const initialState = {
};

export default crudReducer('product', (state = initialState, action) => {
	switch (action.type) {
	case 'CALCULATION_CREATE_ITEM_SUCCESS': {
		return {
			...state,

		};
	}

	default:
		return state;
	}
});

