import { handleActions } from 'redux-actions';

import {
	FETCH_STORES_ATTEMPT,
	FETCH_STORES_FAILED,
	FETCH_STORES_SUCCESS,
	CREATE_STORES_ATTEMPT,
	CREATE_STORES_FAILED,
	CREATE_STORES_SUCCESS,
	UPDATE_STORES_ATTEMPT,
	UPDATE_STORES_FAILED,
	UPDATE_STORES_SUCCESS,
	DELETE_STORES_ATTEMPT,
	DELETE_STORES_FAILED,
	DELETE_STORES_SUCCESS,
} from '../actions/types';

const defaultState = {
	isFetching: false,
	isCreating: false,
	isDeleting: false,
	error: null,
	stores: [],
};

const stores = handleActions({
	[FETCH_STORES_ATTEMPT]: state => ({
		...state,
		isFetching: true,
	}),
	[FETCH_STORES_FAILED]: (state, action) => ({
		...state,
		isFetching: false,
		error: action.payload,
	}),
	[FETCH_STORES_SUCCESS]: (state, action) => ({
		...state,
		isFetching: false,
		error: null,
		stores: action.payload,
	}),
	[CREATE_STORES_ATTEMPT]: state => ({
		...state,
		isCreating: true,
	}),
	[CREATE_STORES_FAILED]: (state, action) => ({
		...state,
		isCreating: false,
		error: action.payload,
	}),
	[CREATE_STORES_SUCCESS]: (state, action) => ({
		...state,
		isCreating: false,
		error: null,
		stores: [...state.stores, action.payload],
	}),
	[UPDATE_STORES_ATTEMPT]: state => ({
		...state,
		isCreating: true,
	}),
	[UPDATE_STORES_FAILED]: (state, action) => ({
		...state,
		isCreating: false,
		error: action.payload,
	}),
	[UPDATE_STORES_SUCCESS]: (state, action) => ({
		...state,
		isCreating: false,
		error: null,
		stores: [
			...state.stores.map(
				store => (store.id === action.payload.id ? action.payload : store)
			)],
	}),
	[DELETE_STORES_ATTEMPT]: state => ({
		...state,
		isDeleting: true,
	}),
	[DELETE_STORES_FAILED]: (state, action) => ({
		...state,
		isDeleting: false,
		error: action.payload,
	}),
	[DELETE_STORES_SUCCESS]: (state, action) => ({
		...state,
		isDeleting: false,
		error: null,
		stores: [...state.stores.filter(store => store.id !== action.payload)],
	}),

}, defaultState);

export default stores;
