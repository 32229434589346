import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	Col, Row, Modal, Button, Form, AutoComplete, Input, Select, DatePicker, Alert, Table,
} from 'antd';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import moment from 'moment';

import { createProduct, fetchProducts, updateProduct } from '../../../../../actions/products';
import { createSaleItem, updateSaleItem } from '../../../../../actions/sales';

const FormItem = Form.Item;

const EditableCell = ({ editable, value, onChange }) => (
	<div>
		{editable
			? <Input style={{ margin: '-5px 0' }} value={value} onChange={e => onChange(e.target.value)} />
			: value
		}
	</div>
);

class CreateSaleItemsModal extends Component {
	constructor(props) {
		super(props);

		this.handleCancel = this.handleCancel.bind(this);
		this.handleSave = this.handleSave.bind(this);
	}

	state = {
		selectedProduct: null,
		product: {
			sku: '',
			name: '',
			price: '',
		},
		price: '',
		data: [
			{
				editable: true,
				edited: false,
				number: 1,
				sku: '',
				quantity: 0,
				price: 0,
			},
		],
	}

	componentDidMount() {
		// this.props.fetchProducts();
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.entity !== nextProps.entity && nextProps.entity) {
			this.setState(() => ({
				data: [
					{
						editable: true,
						edited: false,
						number: 1,
						sku: nextProps.entity.product.sku,
						quantity: nextProps.entity.quantity,
						price: nextProps.entity.grossPrice,
						id: nextProps.entity.id,
					},
				],
			}));
		}
	}


	searchBySkuResults = [];

	searchByNameResults = [];

	handleSearchBySku = (value) => {
		this.searchBySkuResults = this.props.searchProductsBySku.search(value);
	}

	handleSearchByName = (value) => {
		this.searchByNameResults = this.props.searchProductsByName.search(value);
	}

	handleChange = (index, values) => {
		const currentState = {
			...this.state,
			data: [
				...this.state.data.slice(0, index),
				{
					...this.state.data[index],
					edited: true,
					...values,
				},
				...this.state.data.slice(index + 1),
				...(!this.state.data[index].edited && !this.props.entity ? [{
					editable: true,
					edited: false,
					number: this.state.data.length + 1,
					sku: '',
					quantity: 0,
					price: 0,
				}] : []),
			],
		};

		// Object.keys(values).forEach((key) => {
		// 	_.set(currentState.data, key, values[key]);
		// });


		// if (values.price || values.price === '') {
		// 	currentState.price = values.price;
		// }
		// if (values.tax || values.tax === '') {
		// 	currentState.tax = values.tax;
		// }
		// if (values.finalPrice || values.finalPrice === '') {
		// 	currentState.finalPrice = values.finalPrice;
		// }
		// if (values.margin || values.margin === '') {
		// 	currentState.margin = values.margin;
		// }
		// if (values.rebates || values.rebates === '') {
		// 	values.rebates.forEach((rebate, index) => {
		// 		currentState.rebates[index] = rebate;
		// 	});
		// }

		this.setState(currentState);
	}


	handleCancel() {
		this.props.form.resetFields();
		this.props.handleCancel();
	}

	handleSave() {
		const data = this.state.data;
		const promises = [];
		const saleId = this.props.sale.id;

		for (let i = 0; i < data.length; i += 1) {
			const row = data[i];
			if (row.edited && row.id) {
				promises.push(this.props.updateSaleItem(saleId, {
					saleId,
					sku: row.sku,
					total: row.price,
					sold: row.quantity,
					id: row.id,
				}));
			} else if (row.edited) {
				promises.push(this.props.createSaleItem(saleId, {
					saleId,
					sku: row.sku,
					total: row.price,
					sold: row.quantity,
				}));
			}
		}

		return Promise.all(promises)
			.then(() => this.props.handleSave())
			.then(() => this.props.form.resetFields());
	}

	renderColumn = (text, record, column, index) => (
		<EditableCell
			editable={record.editable}
			value={text}
			onChange={value => this.handleChange(index, {
				[column]: value,
			})}
		/>
	)

	render() {
		if (!this.props.visible) {
			return null;
		}

		return (
			<Modal

				width={900}
				visible={this.props.visible}
				title={this.props.entity ? 'Izmena stavki prodaje' : 'Dodavanje stavki prodaje'}
				onCancel={this.handleCancel}
				footer={[
					<Button key="cancel" onClick={this.handleCancel}>Poništi</Button>,
					<Button key="save" type="primary" loading={this.props.isCreating} onClick={this.handleSave}>
					Sačuvaj
					</Button>,
				]}
			>
				<Table
					pagination={false}
					size="middle"
					style={{
						margin: -24,
						borderBottom: 0,
						marginBottom: -25,
					}}
					rowKey="number"
					columns={[
						{
							key: 'number',
							dataIndex: 'number',
							title: 'Br.',
						},
						{
							key: 'sku',
							dataIndex: 'sku',
							title: 'Šifra',
							render: (text, record, index) => this.renderColumn(text, record, 'sku', index),

						},
						{
							key: 'quantity',
							dataIndex: 'quantity',
							title: 'Količina',
							render: (text, record, index) => this.renderColumn(text, record, 'quantity', index),
						},
						{
							key: 'price',
							dataIndex: 'price',
							title: 'Cena',
							render: (text, record, index) => this.renderColumn(text, record, 'price', index),
						},
					]}
					dataSource={this.state.data}
				/>
			</Modal>
		);
	}
}

CreateSaleItemsModal.propTypes = {
	formItemProps: PropTypes.shape({}),
	formLayout: PropTypes.string,
	visible: PropTypes.bool.isRequired,
	isCreating: PropTypes.bool.isRequired,
	handleCancel: PropTypes.func.isRequired,
	handleSave: PropTypes.func.isRequired,


	createSaleItem: PropTypes.func.isRequired,
	updateSaleItem: PropTypes.func.isRequired,
	createProduct: PropTypes.func.isRequired,
	fetchProducts: PropTypes.func.isRequired,
	updateProduct: PropTypes.func.isRequired,

	entity: PropTypes.shape({
		id: PropTypes.number,
		product: PropTypes.shape({}),
	}),

	pricechange: PropTypes.shape({
		id: PropTypes.number,
	}).isRequired,
	searchProductsBySku: PropTypes.func.isRequired,
	searchProductsByName: PropTypes.func.isRequired,
	convert: PropTypes.shape({}),
};

CreateSaleItemsModal.defaultProps = {
	width: 700,
	context: null,
	formItemProps: {},
	entity: null,
	formLayout: 'horizontal',
	handleChange: null,
	handleSave: () => {},
	convert: {},
};

function mapStateToProps(state) {
	return {
		isCreating: state.pricechanges.isCreating,
		searchProductsBySku: state.search.productBySku,
		searchProductsByName: state.search.productByName,

	};
}

function mapPropsToFields(props) {
	if (!props.entity) {
		return {};
	}

	return {
		..._.mapValues(props.entity, entity => (Form.createFormField({ value: entity }))),
		product: {
			..._.mapValues(props.entity.product, product => (Form.createFormField({ value: product }))),
		},
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		createProduct,
		updateProduct,
		fetchProducts,
		createSaleItem,
		updateSaleItem,
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ mapPropsToFields })(CreateSaleItemsModal));
