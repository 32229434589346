import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import localForage from 'localforage';

import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import { multiClientMiddleware } from 'redux-axios-middleware';
import { push } from 'react-router-redux';
import axios from 'axios';


import rootReducer from '../reducers';
import { defaultClient, ecrServiceClient } from './client';

const loggerMiddleware = createLogger();

const persistConfig = {
	key: 'root',
	storage: localForage,
	blacklist: ['search'],
};

const clients = {
	default: {
		client: defaultClient,
	},
	ecr: {
		client: ecrServiceClient,
	},
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function configureStore(preloadedState, history, routerMiddleware) {
	const store = createStore(
		rootReducer,
		preloadedState,
		composeWithDevTools(
			applyMiddleware(
				routerMiddleware,
				thunkMiddleware,
				multiClientMiddleware(clients, {
					returnRejectedPromiseOnError: true,
					onError(response) {
						if (response.error.response && response.error.response.status === 401) {
							// we are not authorized, redirect to login
							localStorage.removeItem('token');
							sessionStorage.removeItem('token');
							delete defaultClient.defaults.headers.common['x-access-token'];
							delete axios.defaults.headers.common['x-access-token'];
							store.dispatch({
								type: 'LOGOUT_SUCCESS',
							});
							store.dispatch(push('/'));
						}
					},
				}),
				loggerMiddleware,
			)
		)
	);
	const persistor = persistStore(store);
	return { store, persistor };
}
