import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	Col, Row, Modal, Button, Form, Input, Select, DatePicker, Spin, List,
} from 'antd';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import moment from 'moment';

import { createCalculation, updateCalculation } from '../../../../../actions/calculations';
import { fetchStores } from '../../../../../actions/stores';
import { fetchInvoices } from '../../../../../actions/invoices';

const FormItem = Form.Item;

class CreateCalculation extends Component {
	constructor(props) {
		super(props);


		this.handleCancel = this.handleCancel.bind(this);
		this.handleSave = this.handleSave.bind(this);
	}

	state = {
		invoices: [],
	}

	componentDidMount() {
		this.props.fetchStores();
		this.props.fetchInvoices();
	}

	componentWillReceiveProps(props) {
		if (props.entity && this.props.entity !== props.entity) {
			this.updateInvoices(props.entity.storeId);
		}
	}

	getProperties = () => [
		{
			label: 'Broj kalkulacije',
			property: 'number',
			rules: [],
			component: <Input />,
		},
		{
			label: 'Prodavnica',
			property: 'storeId',
			rules: [],
			component: <Select
				showSearch
				onSelect={(value) => {
					this.updateInvoices(value);
				}}
				filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
			>
				{this.props.isLoadingStores ? <Select.Option className="bo-select-loading" key="loading" disabled><Spin /></Select.Option> : null}
				{this.props.stores.map(
					store => <Select.Option key={store.id} value={store.id}>{store.name}</Select.Option>
				)
				}
              </Select>,
		},
		{
			label: 'Faktura',
			property: 'invoiceId',
			rules: [{
				required: true, message: 'Faktura je obavezna',
			}],
			component: <Select
				showSearch
				onSelect={(value) => {
					const invoice = _.find(this.state.invoices, { id: parseInt(value, 10) });
					this.props.form.setFieldsValue({
						date: moment(invoice.date),
					});
				}}
				dropdownMatchSelectWidth={false}
				filterOption={(input, option) => option.props.plainText.indexOf(input.toLowerCase()) >= 0}
				invoices={this.props.invoices}
				loading={this.props.isLoadingInvoices}
			>
				{
					this.state.invoices
						.map(
							invoice => (
								<Select.Option
									key={invoice.id}
									value={invoice.id}
									plainText={`${moment(invoice.date).format('L')} [${invoice.distributor.name}]: ${invoice.number}`.toLowerCase()}
								>
									{moment(invoice.date).format('L')} [{invoice.distributor.name}]: <strong>{invoice.number}</strong>
								</Select.Option>
							)
						)
				}
              </Select>,
		},
		{
			label: 'Datum kalkulacije',
			property: 'date',
			rules: [{
				required: true, message: 'Datum kalkulacije je obavezan',
			}],
			component: <DatePicker />,
		},
	];

	updateInvoices = (value) => {
		this.setState(() => ({
			invoices: _.sortBy(this.props.invoices.filter(invoice => invoice.storeId === parseInt(value, 10)), 'date'),
		}));
	}

	handleCancel() {
		this.props.form.resetFields();
		this.props.handleCancel();
	}

	handleSave() {
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const calculationFunc = this.props.entity ? this.props.updateCalculation : this.props.createCalculation;

				return calculationFunc({
					...values,
					...(this.props.entity ? { id: this.props.entity.id } : {}),
				})
					.then(() => this.props.handleSave())
					.then(() => this.props.form.resetFields());
			}

			return false;
		});
	}

	render() {
		if (!this.props.visible) {
			return null;
		}

		const { getFieldDecorator } = this.props.form;


		return (
			<Modal
				width={300}
				visible={this.props.visible}
				title={this.props.entity ? 'Izmena kalkulacije' : 'Dodavanje kalkulacije'}
				onCancel={this.handleCancel}
				footer={[
					<Button key="cancel" onClick={this.handleCancel}>Poništi</Button>,
					<Button key="save" type="primary" loading={this.props.isCreating} onClick={this.handleSave}>
					Sačuvaj
					</Button>,
				]}
			>
				<Form
					layout={this.props.formLayout}
				>
					{this.getProperties().map((property) => {
						switch (property.type) {
						case 'columns':
							return (
								<Row
									align="bottom"
									key={property.key}
									gutter={8}
								>
									{property.columns.map(column => (
										<Col
											span={column.span}
											key={column.property}
										>
											<FormItem
												labelCol={column.labelCol || { span: 24 }}
												wrapperCol={column.wrapperCol || { span: 24 }}
												label={column.label}
												colon={column.colon}
												{...this.props.formItemProps}
											>
												{getFieldDecorator(column.property, {
													rules: column.rules,
													initialValue: column.initialValue,
												})(
													column.component
												)}
											</FormItem>
										</Col>))}
								</Row>
							);
						default:
							return (
								<FormItem
									labelCol={property.labelCol || { span: 24 }}
									wrapperCol={property.wrapperCol || { span: 24 }}
									label={property.label}
									colon={property.colon}
									key={property.property}
									{...this.props.formItemProps}
								>
									{getFieldDecorator(property.property, {
										rules: property.rules,
										initialValue: property.initialValue,
									})(
										property.component
									)}
								</FormItem>
							);
						}
					})}
				</Form>
			</Modal>
		);
	}
}

CreateCalculation.propTypes = {
	formItemProps: PropTypes.shape({}),
	formLayout: PropTypes.string,
	visible: PropTypes.bool.isRequired,
	isCreating: PropTypes.bool.isRequired,
	handleCancel: PropTypes.func.isRequired,
	handleSave: PropTypes.func.isRequired,


	fetchStores: PropTypes.func.isRequired,
	fetchInvoices: PropTypes.func.isRequired,
	createCalculation: PropTypes.func.isRequired,
	updateCalculation: PropTypes.func.isRequired,

	entity: PropTypes.shape({
		id: PropTypes.number,
		storeId: PropTypes.number,
	}),

	calculation: PropTypes.shape({
		id: PropTypes.number,
	}).isRequired,

	isLoadingInvoices: PropTypes.bool.isRequired,
	stores: PropTypes.arrayOf(
		PropTypes.shape({})
	).isRequired,
	invoices: PropTypes.arrayOf(
		PropTypes.shape({})
	).isRequired,
	isLoadingStores: PropTypes.bool.isRequired,
};

CreateCalculation.defaultProps = {
	width: 700,
	context: null,
	formItemProps: {},
	entity: null,
	formLayout: 'horizontal',
	handleSave: () => { },
};

function mapStateToProps(state) {
	return {
		isCreating: state.calculations.isCreating,
		isLoadingInvoices: state.invoices.isFetching,
		isLoadingStores: state.stores.isFetching,
		stores: state.stores.stores,
		invoices: state.invoices.invoices,
	};
}

function mapPropsToFields(props) {
	if (!props.entity) {
		return {};
	}

	return {
		..._.mapValues(props.entity, entity => (Form.createFormField({ value: entity }))),
		date: Form.createFormField({ value: props.entity.date ? moment(props.entity.date) : null }),
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		fetchInvoices,
		createCalculation,
		updateCalculation,
		fetchStores,
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ mapPropsToFields })(CreateCalculation));
