import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';

function mapStateToProps(state) {
	return {
		isAuthenticated: state.auth.isAuthenticated,
	};
}

function withAuth(WrappedComponent, protectedRoute) {
	function Wrapper(props) {
		if (props.isAuthenticated && !protectedRoute) {
			return (<Redirect
				to={{
					pathname: '/app',
					state: { from: props.location },
				}}
			/>);
		}

		if (!props.isAuthenticated && protectedRoute) {
			return (<Redirect
				to={{
					pathname: '/',
					state: { from: props.location },
				}}
			/>);
		}

		return <WrappedComponent {...props} />;
	}

	Wrapper.propTypes = {
		isAuthenticated: PropTypes.bool.isRequired,
		location: PropTypes.shape({}).isRequired,
	};

	return withRouter(connect(mapStateToProps)(Wrapper));
}


export default withAuth;
