import pluralize from 'pluralize';
import _ from 'lodash';


export default function crudReducer(property, additionalReducers, additionalDefaultState = {}) {
	const propertyPlural = pluralize(property);
	const propertyUppercase = _.upperCase(property);
	const propertyUpperFirst = _.upperFirst(property);
	const propertyPluralUppercase = _.upperCase(propertyPlural);
	const defaultState = {
		isFetched: false,
		isFetchedSingle: false,
		isFetching: false,
		isCreating: false,
		isCreatingItem: false,
		isDeleting: false,
		isDeletingItem: false,
		isFetchingSingle: false,
		[`single${propertyUpperFirst}`]: null,
		error: null,
		errorSingle: null,
		[propertyPlural]: [],
		[`${propertyPlural}byId`]: {},
		...additionalDefaultState,
	};

	return function reducer(state = defaultState, action) {
		switch (action.type) {
		case `FETCH_${propertyPluralUppercase}`: {
			return {
				...state,
				isFetching: true,
			};
		}
		case `FETCH_${propertyPluralUppercase}_SUCCESS`: {
			return {
				...state,
				isFetched: true,
				isFetching: false,
				[propertyPlural]: action.payload.data,
				[`${propertyPlural}ById`]: _.keyBy(action.payload.data, 'id'),
			};
		}
		case `FETCH_${propertyPluralUppercase}_FAIL`: {
			return {
				...state,
				isFetching: false,
				error: action.error.response,
			};
		}
		case `FETCH_${propertyUppercase}`: {
			return {
				...state,
				isFetchingSingle: true,
			};
		}

		case `FETCH_${propertyUppercase}_SUCCESS`: {
			return {
				...state,
				isFetchedSingle: true,
				isFetchingSingle: false,
				[`single${propertyUpperFirst}`]: action.payload.data,
			};
		}
		case `FETCH_${propertyUppercase}_FAIL`: {
			return {
				...state,
				isFetchingSingle: false,
				errorSingle: action.error.response,
			};
		}
		case `UPDATE_${propertyUppercase}`:
		case `CREATE_${propertyUppercase}`: {
			return {
				...state,
				isCreating: true,
			};
		}
		case `UPDATE_${propertyUppercase}_ITEM`:
		case `CREATE_${propertyUppercase}_ITEM`: {
			return {
				...state,
				isCreatingItem: true,
			};
		}
		case `CREATE_${propertyUppercase}_SUCCESS`: {
			const update = !!state[`${propertyPlural}ById`][action.payload.data.id];
			return {
				...state,
				isCreating: false,
				[propertyPlural]: [...state[propertyPlural], action.payload.data],
				[`${propertyPlural}ById`]: {
					...state[`${propertyPlural}ById`],
					[action.payload.data.id]: {
						...action.payload.data,
						...(update ? {
							[`${property}Items`]: [],
						} : {}),
					},
				},
			};
		}
		case `CREATE_${propertyUppercase}_ITEM_SUCCESS`: {
			return {
				...state,
				isCreatingItem: false,
				[`${propertyPlural}ById`]: {
					...state[`${propertyPlural}ById`],
					[action.payload.data[`${property}Id`]]: {
						...state[`${propertyPlural}ById`][action.payload.data[`${property}Id`]],
						[`${property}Items`]: [
							...state[`${propertyPlural}ById`][action.payload.data[`${property}Id`]][`${property}Items`] || [],
							action.payload.data,
						],
					},
				},
			};
		}
		// @TODO CREATE_${propertyUppercase}_ITEM_FAIL
		case `UPDATE_${propertyUppercase}_FAIL`:
		case `CREATE_${propertyUppercase}_FAIL`: {
			return {
				...state,
				isCreating: false,
				error: action.error.response,
			};
		}
		case `UPDATE_${propertyUppercase}_SUCCESS`: {
			return {
				...state,
				isCreating: false,
				[propertyPlural]: [
					...state[propertyPlural].map(
						item => (item.id === action.payload.data.id ? action.payload.data : item)
					),
				],
				[`${propertyPlural}ById`]: {
					...state[`${propertyPlural}ById`],
					[action.payload.data.id]: action.payload.data,
				},
			};
		}
		// @TODO UPDATE_${propertyUppercase}_ITEM
		// @TODO DELETE_${propertyUppercase}_ITEM
		case `DELETE_${propertyUppercase}`: {
			return {
				...state,
				isDeleting: true,
			};
		}
		case `DELETE_${propertyUppercase}_SUCCESS`: {
			const { id } = action.meta.previousAction.payload.request;
			return {
				...state,
				isDeleting: false,
				[propertyPlural]: [...state[propertyPlural].filter(item => item.id !== id)],
				[`${propertyPlural}ById`]: _.omit(state[`${propertyPlural}ById`], id),
			};
		}
		case `DELETE_${propertyUppercase}_FAIL`: {
			return {
				...state,
				isDeleting: false,
				error: action.error.response,
			};
		}

		default: {
			if (!additionalReducers) {
				return state;
			}

			return (additionalReducers(state, action));
		}
		}
	};
}
