
import _ from 'lodash';
import crudReducer from './crudReducer';

export default crudReducer('inventoryItem', (state, action) => {
	switch (action.type) {
	case 'INVENTORY_ITEMS_IMPORT': {
		return {
			...state,
			isCreating: true,
		};
	}
	case 'INVENTORY_ITEMS_IMPORT_SUCCESS': {
		return {
			...state,
			isCreating: false,
			inventoryItemsById: {
				...state.inventoryItemsById,
				...(_.keyBy(action.payload.data, 'id')),
			},
			inventoryItems: [
				...state.inventoryItems,
				...action.payload.data,
			],
		};
	}
	default:
		return state;
	}
});

