import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Input } from 'antd';

class ReferenceInput extends Component {
	constructor(props) {
		super(props);

		const value = this.props.value || {}; // eslint-disable-line
		this.state = {
			number: value.number || '',
			model: value.model || '',
		};

		this.handleNumberChange = this.handleNumberChange.bind(this);
		this.handleModelChange = this.handleModelChange.bind(this);
		this.triggerChange = this.triggerChange.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		// Should be a controlled component.
		if ('value' in nextProps) {
			const value = nextProps.value; // eslint-disable-line
			this.setState(value);
		}
	}
	handleNumberChange(e) {
		const number = e.target.value;
		if (!('value' in this.props)) {
			this.setState({ number });
		}
		this.triggerChange({ number });
	}
	handleModelChange(e) {
		const model = e.target.value;
		if (!('value' in this.props)) {
			this.setState({ model });
		}
		this.triggerChange({ model });
	}
	triggerChange(changedValue) {
		// Should provide an event to pass value to Form.
		const onChange = this.props.onChange;
		if (onChange) {
			onChange(Object.assign({}, this.state, changedValue));
		}
	}


	render() {
		const { size } = this.props;
		const state = this.state;
		return (
			<Input.Group compact>
				<Input
					type="text"
					size={size}
					value={state.model}
					onChange={this.handleModelChange}
					style={{ width: '35%' }}
				/>
				<Input
					value={state.number}
					size={size}
					style={{ width: '65%' }}
					onChange={this.handleNumberChange}
				/>
			</Input.Group>
		);
	}
}

ReferenceInput.defaultProps = {
	size: 'default',
	onChange: () => {},
};

ReferenceInput.propTypes = {
	size: PropTypes.string,
	onChange: PropTypes.func,
};

export default ReferenceInput;
