

import React from 'react';
import { Input, Select } from 'antd';

import {
	fetchUsers, createUser, deleteUser, updateUser,
} from '../../../actions/users';
import CrudPage from '../../../components/CrudPage';

const { Option } = Select;

function Users() {
	return (
		<CrudPage
			fetchAction={fetchUsers}
			createAction={createUser}
			deleteAction={deleteUser}
			updateAction={updateUser}
			createTitle="Dodaj Korisnika"
			editTitle="Izmeni Korisnika"
			addButtonText="Dodaj Korisnika"
			deleteButtonPrompt="Da li ste sigurni da želite da obrišete ovog korisnika?"
			stateProperty="users"
			columns={[
				{
					title: 'Ime',
					dataIndex: 'firstName',
					key: 'firstName',
				},
				{
					title: 'Prezime',
					dataIndex: 'lastName',
					key: 'lastName',
				},
				{
					title: 'E-Mail',
					dataIndex: 'email',
					key: 'email',
				},
				{
					title: 'Nivo',
					dataIndex: 'level',
					key: 'level',
				},
			]}
			properties={[
				{
					label: 'Ime',
					property: 'firstName',
					rules: [{
						required: true, message: 'Ime je obavezno!',
					}],
					component: <Input />,
				},
				{
					label: 'Prezime',
					property: 'lastName',
					rules: [{
						required: true, message: 'Prezime je obavezno!',
					}],
					component: <Input />,
				},
				{
					label: 'E-Mail',
					property: 'email',
					rules: [{
						required: true, message: 'E-Mail je obavezan!',
					},
					{
						type: 'email', message: 'E-Mail nije ispravan!',
					},
					],
					component: <Input />,
				},
				{
					label: 'Lozinka',
					property: 'password',
					rules: [{
						required: true, message: 'Lozinka je obavezna!',
					}],
					component: <Input type="password" />,
				},
				{
					label: 'Nivo',
					property: 'level',
					rules: [{
						required: true, message: 'Nivo je obavezan!',
					}],
					component: <Select>
						<Option value="superadmin">Super Admin</Option>
						<Option value="admin">Admin</Option>
						<Option value="employee">Zaposleni</Option>
						<Option value="member">Korisnik</Option>
					</Select>,
				},
			]}
		/>
	);
}

export default Users;
