// @flow
import { types, flow } from 'mobx-state-tree';
import keyBy from 'lodash/keyBy';
import forEach from 'lodash/forEach';

import localforage from 'localforage';

import { defaultClient } from '../store/client';

export const Ean = types
	.model('Barcode', {
		id: types.identifierNumber,
		barcode: types.maybeNull(types.string),
	});

export type EanType = {
	id: number,
	barcode: ?string,
}

export const Product = types
	.model('Product', {
		id: types.identifierNumber,
		sku: types.number,
		name: types.string,
		price: types.maybeNull(types.number),
		barcodes: types.optional(types.array(Ean), []),
	});

export type ProductType = {
	id: number,
	sku: number,
	name: string,
	price: number,
	barcodes: Array<EanType>
}


export const ProductStore = types
	.model('ProductStore', {
		products: types.array(Product),
		fetched: types.boolean,
		loading: types.boolean,
	})
	.actions((self) => {
		const fetchProducts = flow(function* fetchProducts() {
			if (!self.fetched) {
				self.loading = true;
			}

			try {
				const response = yield defaultClient.get('/products/');
				self.fetched = true;
				if (response.data) {
					self.products.replace(response.data);
				}
			} catch (error) {
				console.log('network error');
			} finally {
				self.loading = false;
			}
		});
		const hydrate = flow(function* hydrate() {
			const stored = yield localforage.getItem('products');
			if (stored) {
				forEach(stored, (value, key) => {
					self[key] = value;
				});
			}

			return stored;
		});

		return {
			fetchProducts,
			hydrate,
		};
	})
	.views(self => ({
		get productsByEan() {
			return self.products
				.reduce((prev, curr) => {
					curr.barcodes.forEach((barcode) => {
						prev[barcode.barcode] = prev[barcode.barcode] || [];
						prev[barcode.barcode].push(curr);
					});
					return prev;
				}, {});
		},
		get productsBySku() {
			return keyBy(self.products, 'sku');
		},
	}));

export type ProductStoreType = {
	productsByEan: Map<string, Object>,
	productsBySku: Map<string, Object>
}
