// @flow
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import SocketIo from 'socket.io-client';
import trim from 'lodash/trim';

import NumberInfo from 'ant-design-pro/lib/NumberInfo';
import numeral from 'numeral';
import {
	Table, Button, notification, Input, Popover, Divider,
} from 'antd';

import QuantityInput from './QuantityInput';
import PaymentModal from './PaymentModal';
import type { ProductStoreType, ProductType } from '../../../stores/Product';
import type { SaleStoreType } from '../../../stores/Sale';

type Props = {
	height: number,
	sale: any,
	active: boolean,
	socket: SocketIo,
	store: {
		productStore: ProductStoreType,
		saleStore: SaleStoreType,
	}
}

type State = {
	popovers: {
		sku: boolean,
		ean: boolean,
	},
	values: {
		sku: string,
		ean: string,
	},
	paymentModalVisible: boolean,
}

notification.config({
	placement: 'bottomRight',
});

@inject(stores => ({
	store: stores.store,
}))
@observer
class Sale extends Component<Props, State> {
	state = {
		popovers: {
			sku: false,
			ean: false,
		},
		values: {
			sku: '',
			ean: '',
		},
		paymentModalVisible: false,
	}

	focusedInput: boolean = false

	componentDidMount() {
		const { socket } = this.props;
		socket.on('ean', this.onEan);

		document.addEventListener('keypress', this.handleKeyPress);
	}

	componentWillUnmount() {
		const { socket } = this.props;
		socket.off('ean', this.onEan);

		document.removeEventListener('keypress', this.handleKeyPress);
	}

	handleKeyPress = (e: KeyboardEvent) => {
		const { active } = this.props;
		const { paymentModalVisible } = this.state;

		if (active && !paymentModalVisible) {
			if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) {
				if (!this.focusedInput) {
					this.setState(state => ({
						popovers: {
							...state.popovers,
							sku: true,
						},
					}));
				}
			}
		}
	}

	onEan = (ean: string) => {
		const { active } = this.props;
		if (active) {
			this.addByEan(ean);
		}
	}

	addByEan = (emittedEan:string) => {
		const { store: { productStore: { productsBySku } } } = this.props;
		const self = this;
		const ean: string = trim(`${emittedEan}`);
		if (ean[0] === '2') {
			const sku = parseInt(ean.substr(2, 5), 10);
			const amount = parseInt(ean.substr(7, 5), 10) / 1000;

			// $FlowFixMe
			const product: ProductType = productsBySku[sku];

			if (product) {
				return self.add(product, amount);
			}

			return notification.error({
				key: 'eanError',
				message: 'Proizvod nije pronađen',
				description: `Proizvod sa šifrom ${sku} nije pronađen. Pokušajte drugi metod unosa.`,
				duration: 10,
			});
		}
		const product = self.props.store.productStore.productsByEan[ean] ? self.props.store.productStore.productsByEan[ean][0] : null;
		if (product) {
			return self.add(product, 1);
		}

		return notification.error({
			key: 'eanError',
			message: 'Proizvod nije pronađen',
			description: `Proizvod sa bar kodom ${ean} nije pronađen. Pokušajte drugi metod unosa.`,
			duration: 10,
		});
	}

	addBySku = (sku: number, amount: number = 1) => {
		const { store: { productStore: { productsBySku } } } = this.props;

		// $FlowFixMe
		const product: ProductType = productsBySku[sku];

		if (product) {
			return this.add(product, amount);
		}

		return notification.error({
			key: 'eanError',
			message: 'Proizvod nije pronadjen',
			description: `Proizvod sa šifrom ${sku} nije pronađen. Pokušajte drugi metod unosa.`,
			duration: 10,
		});
	}

	add = (product: ProductType, amount: number) => {
		const { sale: { addItem } } = this.props;
		addItem(product, amount);
	}

	render() {
		const { sale, height, store: { saleStore } } = this.props;
		const { paymentModalVisible } = this.state;
		const columns = [{
			title: 'Šifra',
			dataIndex: 'product.sku',
			width: 80,
		}, {
			title: 'Naziv',
			dataIndex: 'product.name',
		}, {
			title: 'Količina',
			dataIndex: 'amount',
			width: 150,
			render: (text, record) => (
				<QuantityInput
					onFocus={() => {
						this.focusedInput = true;
					}}
					onBlur={() => {
						this.focusedInput = false;
					}}
					record={record}
					sale={sale}
					type="number"
				/>
			),
		}, {
			title: 'Cena',
			dataIndex: 'price',
			render: text => `${numeral(text).format('0,0.00')} RSD`,
			width: 150,
		}, {
			title: 'Iznos',
			key: 'total',
			render: record => `${numeral(record.price * record.amount).format('0,0.00')} RSD`,
			width: 150,
		}, {
			title: 'Akcije',
			key: 'actions',
			width: 130,
			render: record => (
				<span>
					<Button
						type="danger"
						size="small"
						icon="delete"
						style={{ marginRight: 8 }}
						onClick={() => sale.removeItem(record.id)}
					>
						Storno
					</Button>
				</span>
			),
		},
		];

		const { popovers } = this.state;

		return (
			<div
				style={{ height: height - 81 }}
			>
				<div className="wrap">
					<div className="content">
						<div
							style={{
								float: 'left',
								marginBottom: 24,
							}}
						>
							<Popover
								trigger="click"
								visible={popovers.sku}
								onVisibleChange={visible => this.setState(state => ({
									popovers: {
										...state.popovers,
										sku: visible,
									},
								}))}
								title="Unesite šifru"
								content={(
									<Input
										ref={(skuInput) => {
											if (skuInput) {
												skuInput.focus();
											}
										}}
										onFocus={() => {
											this.focusedInput = true;
										}}
										onBlur={() => {
											this.focusedInput = false;
										}}
										onChange={(event) => {
											const { value } = event.target;
											this.setState(state => ({
												values: {
													...state.values,
													sku: value,
												},
											}));
										}}
										value={`${this.state.values.sku}`}
										onPressEnter={(event) => {
											const { value } = event.target;

											if (value.includes('x') || value.includes('*')) {
												const parts = value.split(/[x*]/);
												this.addBySku(parts[1], parseFloat(parts[0]));
											} else {
												this.addBySku(value);
											}
											this.setState(state => ({
												popovers: {
													...state.popovers,
													sku: false,
												},
												values: {
													...state.values,
													sku: '',
												},
											}));
										}}
									/>
								)}
							>
								<Button
									icon="tag"
									style={{ marginRight: 8 }}
								>Šifra
								</Button>
							</Popover>
							<Popover
								trigger="click"
								visible={popovers.ean}
								onVisibleChange={visible => this.setState(state => ({
									popovers: {
										...state.popovers,
										ean: visible,
									},
								}))}
								title="Unesite bar kod"
								content={(
									<Input
										ref={(eanInput) => {
											if (eanInput) {
												eanInput.focus();
											}
										}}
										onFocus={() => {
											this.focusedInput = true;
										}}
										onBlur={() => {
											this.focusedInput = false;
										}}
										value={this.state.values.ean}
										onChange={(event) => {
											const { value } = event.target;
											this.setState(state => ({
												values: {
													...state.values,
													ean: value,
												},
											}));
										}}
										onPressEnter={(event) => {
											const { value } = event.currentTarget;

											this.addByEan(value);

											this.setState(state => ({
												popovers: {
													...state.popovers,
													ean: false,
												},
												values: {
													...state.values,
													ean: '',
												},
											}));
										}}
									/>
								)}
							>
								<Button
									icon="barcode"
									style={{ marginRight: 8 }}
								>Bar Kod
								</Button>
							</Popover>
							<Button icon="folder" disabled style={{ marginRight: 8 }}>Kategorije</Button>
							<Button icon="search" disabled style={{ marginRight: 8 }}>Pretraga</Button>
							<Divider type="vertical" />
							<Button icon="user" disabled style={{ marginLeft: 8, marginRight: 8 }}>Član</Button>
							<Divider type="vertical" />
							<Button
								icon="delete"
								type="danger"
								style={{ marginLeft: 8, marginRight: 8 }}
								onClick={() => {
									saleStore.removeSale(sale);
								}}
							>
								Storno
							</Button>
							<Button
								onClick={() => {
									this.setState(() => ({
										paymentModalVisible: true,
									}));
								}}
								icon="check"
								type="primary"
								style={{ marginRight: 8 }}
							>
								Plaćanje
							</Button>
						</div>
						<div style={{ float: 'right' }}>
							<NumberInfo
								style={{ textAlign: 'right' }}
								total={(
									<h2 style={{ marginRight: -32 }}>
										<strong>{numeral(
											sale.total
										).format('0,0.00')}
										</strong> RSD
									</h2>
								)}
							/>
						</div>
						<div style={{ clear: 'both' }} />
					</div>
				</div>
				<Table
					size="small"
					columns={columns}
					dataSource={sale.itemsAsArray}
					bordered
					scroll={{ y: height - 184 }}
					pagination={false}
					rowKey="id"
				/>
				<PaymentModal
					visible={paymentModalVisible}
					amount={sale.total}
					sale={sale}
				/>
			</div>

		);
	}
}

export default Sale;
