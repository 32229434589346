import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import 'ant-design-pro/dist/ant-design-pro.css';
import 'ant-design-pro/lib/PageHeader/index';

import './index.css';

import App from './App';
import registerServiceWorker from './registerServiceWorker';

// Add this import:

function deepMap(obj, iterator, context) {
	return _.transform(obj, (result, val, key) => {
		result[key] = _.isObject(val)
			? /* && !_.isDate(val) */ deepMap(val, iterator, context)
			: iterator.call(context, val, key, obj);
	});
}

_.mixin({
	deepMap,
});

// Wrap the rendering in a function:
ReactDOM.render(
	// Wrap App inside AppContainer
	<App />,
	document.getElementById('root')
);

registerServiceWorker();
