import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Spin, Row, Modal, Button, Form, AutoComplete, Input, Select, DatePicker, Alert } from 'antd';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import _ from 'lodash';

import { createProduct, fetchProducts, updateProduct } from '../../../../../actions/products';
import { fetchStores } from '../../../../../actions/stores';
import { createInventoryItem, updateInventoryItem } from '../../../../../actions/inventoryItems';

const FormItem = Form.Item;

class CreateInventoryItemModal extends Component {
	constructor(props) {
		super(props);

		this.handleCancel = this.handleCancel.bind(this);
		this.handleSave = this.handleSave.bind(this);
	}

	state = {
		selectedProduct: null,
		product: {
			sku: '',
			name: '',
			shortName: '',
			ean: '',
		},
		tax: '',
		price: '',
	}

	componentDidMount() {
		this.props.fetchStores();
		this.props.fetchProducts();
	}

	componentWillReceiveProps(props) {
		if (!this.props.entity && props.entity) {
			this.selectProduct(props.entity.product, () => {
				this.handleChange({
					...props.entity,
				});
			}
			);
		}
	}

	getProperties = () => [
		...(this.state.selectedProduct ? [{
			type: 'columns',
			key: 'selectedProduct',
			columns: [
				{
					span: 24,
					property: 'selectedProductInfo',
					component: <Alert
						message={`Izabran je proizvod ${this.state.selectedProduct.sku} - ${this.state.selectedProduct.name}.`}
						banner
						type="info"
						closeText="Ukloni"
						style={{ margin: -15 }}
						showIcon={false}
						onClose={() => {
							this.resetProduct();
						}}
					/>,
				},
			],
		}] : []),
		{
			type: 'columns',
			key: 'productInfo',
			columns: [
				{
					span: 5,
					label: 'Prodavnica',
					property: 'storeId',
					rules: [{
						required: true, message: 'Prodavnica je obavezna',
					}],
					component: <Select
						showSearch
						filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
					>
						{this.props.isLoadingStores ? <Select.Option className="bo-select-loading" key="loading" disabled><Spin /></Select.Option> : null}
						{this.props.stores.map(
							store => <Select.Option key={store.id} value={`${store.id}`}>{store.name}</Select.Option>)
						}
					</Select>,
				},
				{
					span: 3,
					label: 'Šifra',
					property: 'product.sku',
					rules: [
						{ required: true, message: 'Šifra je obavezna' },
					],
					component: <AutoComplete
						defaultActiveFirstOption={false}
						filterOption={false}
						onSearch={this.handleSearchBySku}
						size="large"
						onSelect={(val, options) => {
							this.selectProduct(options.props.data);
						}}
						dropdownMatchSelectWidth={false}
						disabled={!!this.state.selectedProduct}
						dataSource={this.searchBySkuResults.map(d => <AutoComplete.Option key={`product.sku.autocomplete.${d.sku}`} data={d}>{`${d.sku} - ${d.name}`}</AutoComplete.Option>)}
					>
						<Input />
					</AutoComplete>,
				},
				{
					span: 10,
					label: 'Naziv',
					property: 'product.name',
					rules: [
						{ required: true, message: 'Naziv je obavezan' },
					],
					component: <AutoComplete
						defaultActiveFirstOption={false}
						filterOption={false}
						onSearch={this.handleSearchByName}
						size="large"
						onSelect={(val, options) => {
							this.selectProduct(options.props.data);
						}}
						dropdownMatchSelectWidth={false}
						disabled={!!this.state.selectedProduct}
						dataSource={this.searchByNameResults.map(d => <AutoComplete.Option key={`product.name.autocomplete.${d.sku}`} data={d}>{`${d.sku} - ${d.name}`}</AutoComplete.Option>)}
					>
						<Input />
					</AutoComplete>,
				},
				{
					span: 6,
					label: 'Skraćeni naziv',
					property: 'product.shortName',
					rules: [
						{ max: 22, message: 'Skraćeni naziv ne sme biti duži od 22 karaktera' },
					],
					component: <Input
						disabled={!!this.state.selectedProduct}
					/>,
				},
			],
		},
		{
			type: 'columns',
			key: 'priceInfo',
			columns: [
				{
					span: 4,
					label: 'Bar kod',
					property: 'product.ean',
					rules: [

					],
					component: <Select
						mode="tags"
						tokenSeparators={[',', ' ', ';']}
					/>,
				},
				{
					span: 3,
					label: 'Datum isteka',
					property: 'expirationDate',
					rules: [],
					component: <DatePicker
						placeholder=""
						notFoundContent=""
					/>,
				},
				{
					span: 3,
					label: 'Datum dodavanja',
					property: 'date',
					rules: [],
					initialValue: moment(),
					component: <DatePicker
						placeholder=""
						notFoundContent=""
					/>,
				},
				{
					span: 2,
					label: 'Količina',
					property: 'quantity',
					rules: [
						{ required: true, message: 'Količina je obavezna' },
					],
					component: <Input
						type="number"
					/>,
				},
				{
					span: 3,
					label: 'PDV',
					property: 'tax',
					rules: [
						{ required: true, message: 'PDV je obavezan' },
					],
					component: <Input
						addonAfter="%"
						onChange={event => this.handleChange({ tax: event.target.value })}
					/>,
				},
				{
					initialValue: this.state.finalPrice,
					span: 5,
					label: 'Konačna cena',
					property: 'price',
					rules: [],
					component: <Input
						type="number"
						addonAfter="RSD"
						onChange={event => this.handleChange({ price: event.target.value })}
					/>,
				},

			],
		},

	];

	selectProduct = (product, callback = () => {}) => {
		this.setState({
			selectedProduct: product,
		}, () => {
			const ean = product.barcodes.map(barcode => barcode.barcode);
			const lastCalculationItem = _.last(product.calculationItems);

			this.handleChange({
				'product.sku': `${product.sku}`,
				'product.name': product.name,
				'product.shortName': product.shortName,
				'product.ean': ean,
				...(lastCalculationItem ? {
					tax: lastCalculationItem.tax,
					price: lastCalculationItem.finalPrice,
				} : {}),

			});
			this.searchByNameResults = [];
			this.searchBySkuResults = [];
			this.props.form.setFields({
				'product.sku': { value: `${product.sku}` },
				'product.name': { value: product.name },
				'product.shortName': { value: product.shortName },
				'product.ean': { value: ean },
				...(lastCalculationItem ? {
					tax: { value: lastCalculationItem.tax },
					price: { finalPrice: lastCalculationItem.finalPrice },
				} : {}),
			});
			callback();
		});
	};

	resetProduct = () => {
		this.setState({
			selectedProduct: null,
		}, () => {
			this.props.form.setFields({
				'product.sku': { value: '' },
				'product.name': { value: '' },
				'product.shortName': { value: '' },
				'product.ean': { value: [] },
				date: { value: null },
				expirationDate: { value: null },
				tax: { value: '' },
				price: { value: '' },
			});
		});
	}

	searchBySkuResults = [];
	searchByNameResults = [];

	handleSearchBySku = (value) => {
		this.searchBySkuResults = this.props.searchProductsBySku.search(value);
	}

	handleSearchByName = (value) => {
		this.searchByNameResults = this.props.searchProductsByName.search(value);
	}

	handleChange = (values) => {
		const currentState = {
			...this.state,
		};

		Object.keys(values).forEach((key) => {
			_.set(currentState, key, values[key]);
		});

		this.setState(currentState);
	}


	handleCancel() {
		this.resetProduct();
		this.props.form.resetFields();
		this.props.handleCancel();
	}

	handleSave() {
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const productFunc = this.state.selectedProduct ? this.props.updateProduct : this.props.createProduct;
				const itemFunc = this.props.entity ? this.props.updateInventoryItem : this.props.createInventoryItem;

				return productFunc({
					...values.product,
					...(this.state.selectedProduct ? { id: this.state.selectedProduct.id } : {}),
				})
					.then((response) => {
						const product = response.payload.data;

						return itemFunc({
							productId: product.id,
							quantity: values.quantity,
							storeId: values.storeId,
							date: values.date,
							expirationDate: values.expirationDate,
							tax: values.tax,
							price: values.price,
							...(this.props.entity ? { id: this.props.entity.id } : {}),
						});
					})
					.then(() => this.resetProduct())
					.then(() => this.props.handleSave())
					.then(() => this.props.form.resetFields());
			}

			return false;
		});
	}

	render() {
		if (!this.props.visible) {
			return null;
		}

		const { getFieldDecorator } = this.props.form;


		return (<Modal
			width={900}
			visible={this.props.visible}
			title={this.props.entity ? 'Izmena stavke inventara' : 'Dodavanje stavke inventara'}
			onCancel={this.handleCancel}
			footer={[
				<Button key="cancel" onClick={this.handleCancel}>Poništi</Button>,
				<Button key="save" type="primary" loading={this.props.isCreating} onClick={this.handleSave}>
					Sačuvaj
				</Button>,
			]}
		>
			<Form
				layout={this.props.formLayout}
			>
				{this.getProperties().map((property) => {
					switch (property.type) {
					case 'columns':
						return (<Row
							align="bottom"
							key={property.key}
							gutter={8}
						>
							{property.columns.map(column => (
								<Col
									span={column.span}
									key={column.property}
								>
									<FormItem
										labelCol={column.labelCol || { span: 24 }}
										wrapperCol={column.wrapperCol || { span: 24 }}
										label={column.label}
										colon={column.colon}
										{...this.props.formItemProps}
									>
										{getFieldDecorator(column.property, {
											rules: column.rules,
											initialValue: column.initialValue,
										})(
											column.component
										)}
									</FormItem>
								</Col>))}
						</Row>);
					default:
						return (<FormItem
							labelCol={property.labelCol || { span: 24 }}
							wrapperCol={property.wrapperCol || { span: 24 }}
							label={property.label}
							colon={property.colon}
							key={property.property}
							{...this.props.formItemProps}
						>
							{getFieldDecorator(property.property, {
								rules: property.rules,
								initialValue: property.initialValue,
							})(
								property.component
							)}
						</FormItem>);
					}
				})}
			</Form>
		</Modal>);
	}
}

CreateInventoryItemModal.propTypes = {
	formItemProps: PropTypes.shape({}),
	formLayout: PropTypes.string,
	visible: PropTypes.bool.isRequired,
	isCreating: PropTypes.bool.isRequired,
	handleCancel: PropTypes.func.isRequired,
	handleSave: PropTypes.func.isRequired,
	isLoadingStores: PropTypes.bool.isRequired,

	fetchStores: PropTypes.func.isRequired,
	fetchProducts: PropTypes.func.isRequired,
	createInventoryItem: PropTypes.func.isRequired,
	updateInventoryItem: PropTypes.func.isRequired,
	createProduct: PropTypes.func.isRequired,
	updateProduct: PropTypes.func.isRequired,

	entity: PropTypes.shape({
		id: PropTypes.number,
		product: PropTypes.shape({}),
	}),

	searchProductsBySku: PropTypes.func.isRequired,
	searchProductsByName: PropTypes.func.isRequired,
	convert: PropTypes.shape({}),
	stores: PropTypes.arrayOf(
		PropTypes.shape({})
	).isRequired,
};

CreateInventoryItemModal.defaultProps = {
	width: 700,
	context: null,
	formItemProps: {},
	entity: null,
	formLayout: 'horizontal',
	handleChange: null,
	handleSave: () => {},
	convert: {},
};

function mapStateToProps(state) {
	return {
		isLoadingStores: state.stores.isLoading,
		isCreating: state.calculations.isCreating,
		searchProductsBySku: state.search.productBySku,
		searchProductsByName: state.search.productByName,
		stores: state.stores.stores,
	};
}

function mapPropsToFields(props) {
	if (!props.entity) {
		return {};
	}

	return {
		..._.mapValues(props.entity, entity => (Form.createFormField({ value: entity }))),
		product: {
			..._.mapValues(props.entity.product, product => (Form.createFormField({ value: product }))),
			ean: Form.createFormField({ value: props.entity.product.barcodes.map(barcode => barcode.barcode) }),
		},
		date: Form.createFormField({ value: props.entity.date ? moment(props.entity.date) : null }),
		expirationDate: Form.createFormField({ value: props.entity.expirationDate ? moment(props.entity.expirationDate) : null }),
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		createProduct,
		updateProduct,
		fetchProducts,
		createInventoryItem,
		updateInventoryItem,
		fetchStores,
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ mapPropsToFields })(CreateInventoryItemModal));
