import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
	Button, Input, Select, DatePicker, Spin, Tag,
} from 'antd';

import moment from 'moment';
import { sortBy } from 'lodash';

import {
	fetchInvoices, createInvoice, deleteInvoice, updateInvoice, payInvoice,
} from '../../../../actions/invoices';
import { fetchDistributors } from '../../../../actions/distributors';
import { fetchStores } from '../../../../actions/stores';
import CrudPage from '../../../../components/CrudPage';

import ReferenceInput from './referenceInput';
import numberFormat from '../../../../lib/numberFormat';

class Invoices extends Component {
	componentDidMount() {
		this.props.fetchDistributors();
		this.props.fetchStores();
	}

	render() {
		if (this.props.isLoading) {
			return <Spin />;
		}

		const columns = [
			{
				title: 'Broj fakture',
				dataIndex: 'number',
				key: 'number',
			},
			{
				title: 'Poziv na broj',
				dataIndex: 'reference',
				key: 'reference',
				render: (text, record) => {
					if (!record.reference) {
						return '';
					}
					return `${record.reference.model ? `(${record.reference.model})` : ''} ${record.reference.number}`;
				},
			},
			{
				title: 'Iznos',
				dataIndex: 'amount',
				key: 'amount',
				render: text => numberFormat(text),
			},
			{
				title: 'Prodavnica',
				dataIndex: 'store.name',
				key: 'store.name',
				filters: this.props.stores.map(store => ({
					text: store.name,
					value: store.id,
				})),
				filterMultiple: true,
				onFilter: (value, record) => record.store.id === value,
			},
			{
				title: 'Distributer',
				dataIndex: 'distributor.name',
				key: 'distributor.name',
				filters: this.props.distributors.map(distributor => ({
					text: distributor.name,
					value: distributor.id,
				})),
				filterMultiple: true,
				onFilter: (value, record) => record.distributor.id === value,
			},
			{
				title: 'Datum fakture',
				dataIndex: 'date',
				key: 'date',
				render: text => moment(text).format('L'),

			},
			{
				title: 'Datum valute',
				dataIndex: 'dueDate',
				render: (text, record) => {
					const date = moment(text);
					return <span className={date.isBefore(moment()) && !record.paid ? 'bo-due-past' : ''}>{date.format('L')}</span>;
				},
				sorter: (a, b) => (moment(a.dueDate).isBefore(moment(b.dueDate)) ? -1 : 1),

			},
			{
				title: 'Plaćeno',
				dataIndex: 'paid',
				filters: [
					{
						text: <Tag color="green">Plaćeno</Tag>,
						value: '1',
					},
					{
						text: <Tag color="red">Nije Plaćeno</Tag>,
						value: '0',
					},

				],
				defaultFilteredValue: ['0'],
				filterMultiple: true,
				key: 'paid',
				onFilter: (value, record) => (record.paid && value === '1')
					|| (!record.paid && value === '0'),
				render: value => (value
					? <Tag color="green">Plaćeno</Tag>
					: <Tag color="red">Nije Plaćeno</Tag>),
			},
			{
				title: 'Prioritet',
				key: 'priority',
				dataIndex: 'priority',
				render: (text, record) => (
					<Input
						defaultValue={text}
						onBlur={(event) => {
							record.priority = event.target.value;
							this.props.updateInvoice(record);
						}}
					/>
				)
				,
			},
			{
				title: 'Plaćanje',
				key: 'paying',
				render: (text, record) => {
					if (!record.count) {
						return {
							children: '',
							props: {
								rowSpan: 0,
							},
						};
					}

					const obj = {
						children: <span>
							Od: {record.start.format('L')}<br />
							Do: {record.end.format('L')}<br />
							Ukupno: ({record.currentSum})
                </span>,
						props: { rowSpan: record.count },
					};

					return obj;
				},
			},
		];

		return (
			<CrudPage
				processData={(data) => {
					const weeklyIncome = 85000;

					let currentSum = 0;
					let start = moment();

					let count = 0;

					const sorted = sortBy(data, ['priority', 'dueDate']);

					sorted.forEach((item, index) => {
						count += 1;
						currentSum += item.amount;


						if (currentSum > weeklyIncome) {
							const ind = (index - count) + 1;
							sorted[ind] = {
								...sorted[ind],
								start,
								end: start.clone().add(1, 'week'),
								count,
								currentSum,
							};

							start = sorted[ind].end.clone().add(1, 'day');

							currentSum = 0;


							count = 0;
						}
					});

					if (count > 0) {
						const ind = (sorted.length - count);
						sorted[ind] = {
							...sorted[ind],
							start,
							end: start.clone().add(1, 'week'),
							count,
							currentSum,
						};
					}

					return sorted;
				}}
				title="Fakture"
				subTitle="Ovde možete videti sve fakture"
				fetchAction={fetchInvoices}
				createAction={createInvoice}
				deleteAction={deleteInvoice}
				updateAction={updateInvoice}
				createTitle="Dodaj Fakturu"
				editTitle="Izmeni Fakturu"
				addButtonText="Dodaj Fakturu"
				deleteButtonPrompt="Da li ste sigurni da želite da obrišete ovu fakturu?"
				stateProperty="invoices"
				columns={columns}
				tableFooter={(data) => {
					let total = 0;
					let overdue = 0;

					data.forEach((invoice) => {
						if (!invoice.paid) {
							if (moment(invoice.dueDate).isBefore(moment())) {
								overdue += invoice.amount;
							}
							total += invoice.amount;
						}
					});
					return (
						<div>
							<strong>Ukupno za uplatu:</strong> <span>{numberFormat(total)}</span><br />
							<strong>Van valute:</strong> <span>{numberFormat(overdue)}</span>
						</div>
					);
				}}
				tableProps={
					{
						pagination: false,
					}
				}
				convert={{
					date: moment,
					dueDate: moment,
					storeId: storeId => `${storeId}`,
					distributorId: distributorId => `${distributorId}`,
				}}
				extraButtons={(text, record) => [
					<Button
						key="pay"
						onClick={() => {
							this.props.payInvoice(record);
						}}
						icon="pay-circle-o"
					/>,
				]}
				properties={[
					{
						label: 'Prodavnica',
						property: 'storeId',
						rules: [{
							required: true, message: 'Prodavnica je obavezna',
						}],
						component: <Select
							showSearch
							filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						>
							{this.props.isLoadingStores ? <Select.Option className="bo-select-loading" key="loading" disabled><Spin /></Select.Option> : null}
							{this.props.stores.map(
								store => <Select.Option key={store.id} value={`${store.id}`}>{store.name}</Select.Option>
							)
							}
                 </Select>,
					},
					{
						label: 'Distributer',
						property: 'distributorId',
						rules: [{
							required: true, message: 'Distributer je obavezan',
						}],
						component: <Select
							showSearch
							filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						>
							{this.props.isLoadingDistributors ? <Select.Option className="bo-select-loading" key="loading" disabled><Spin /></Select.Option> : null}
							{this.props.distributors.map(
								distributor => <Select.Option key={distributor.id} value={`${distributor.id}`}>{distributor.name}</Select.Option>
							)
							}
                 </Select>,
					},
					{
						label: 'Datum fakture',
						property: 'date',
						rules: [{
							required: true, message: 'Datum fakture je obavezan',
						}],
						component: <DatePicker />,
					},
					{
						label: 'Datum valute',
						property: 'dueDate',
						rules: [{
							required: true, message: 'Datum valute je obavezan',
						}],
						component: <DatePicker />,
					},
					{
						label: 'Broj fakture',
						property: 'number',
						rules: [{
							required: true, message: 'Broj fakture je obavezan!',
						}],
						component: <Input />,
					},
					{
						label: 'Iznos',
						property: 'amount',
						rules: [{
							required: true, message: 'Iznos je obavezan',
						}],
						component: <Input type="number" />,
					},
					{
						label: 'Poziv na broj',
						property: 'reference',
						rules: [],
						component: <ReferenceInput />,
					},
				]}
			/>
		);
	}
}

Invoices.propTypes = {
	fetchDistributors: PropTypes.func.isRequired,
	payInvoice: PropTypes.func.isRequired,
	fetchStores: PropTypes.func.isRequired,
	isLoadingDistributors: PropTypes.bool.isRequired,
	isLoadingStores: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	distributors: PropTypes.arrayOf(
		PropTypes.shape({})
	).isRequired,
	stores: PropTypes.arrayOf(
		PropTypes.shape({})
	).isRequired,
};

function mapStateToProps(state) {
	return {
		isLoading: state.distributors.isFetching || state.stores.isFetching,
		isLoadingDistributors: state.distributors.isFetching,
		isLoadingStores: state.stores.isFetching,
		distributors: state.distributors.distributors,
		stores: state.stores.stores,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		fetchDistributors,
		fetchStores,
		payInvoice,
		updateInvoice,
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
