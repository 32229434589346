import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button } from 'antd';
import { bindActionCreators } from 'redux';

import {
	fetchInventoryItems, createInventoryItem, deleteInventoryItem, updateInventoryItem,
} from '../../../../actions/inventoryItems';
import { fetchStores } from '../../../../actions/stores';
import CrudPage from '../../../../components/CrudPage';
import CreateInventoryItemModal from './CreateInventoryItemModal';
import ImportInventoryItemsModal from './ImportInventoryItemsModal';

class InventoryItems extends Component {
	static propTypes = {
		fetchInventoryItems: PropTypes.func.isRequired,
		createInventoryItem: PropTypes.func.isRequired,
		updateInventoryItem: PropTypes.func.isRequired,
		deleteInventoryItem: PropTypes.func.isRequired,
		fetchStores: PropTypes.func.isRequired,
		isLoadingStores: PropTypes.bool.isRequired,
		isLoadingInvoices: PropTypes.bool.isRequired,
		stores: PropTypes.arrayOf(
			PropTypes.shape({})
		).isRequired,
		invoices: PropTypes.arrayOf(
			PropTypes.shape({})
		).isRequired,
	}

	state = {
		createVisible: false,
		importVisible: false,
		inventoryItem: null,
	}

	render() {
		return (
			<CrudPage
				useMap
				hideDefaultButtons
				headerContents={(
					<div>
						<Button
							style={{ marginRight: '8px' }}
							type="primary"
							onClick={() => {
								this.setState({
									createVisible: true,
								});
							}}
						>
						Dodaj Proizvod u Inventar
						</Button>
						<Button
							onClick={() => {
								this.setState({
									importVisible: true,
								});
							}}
						>
						Uvezi Proizvode u Inventar
						</Button>
					</div>
				)}
				editButtonAction={(record) => {
					this.setState({
						inventoryItem: record,
						createVisible: true,
					});
				}}
				fetchAction={fetchInventoryItems}
				createAction={createInventoryItem}
				deleteAction={deleteInventoryItem}
				updateAction={updateInventoryItem}
				createTitle="Dodaj Proizvod u Inventar"
				editTitle="Izmeni Proizvod u Inventaru"
				addButtonText="Dodaj Proizvod u Inventar"
				deleteButtonPrompt="Da li ste sigurni da želite da obrišete ovaj proizvod?"
				stateProperty="inventoryItems"
				columns={[
					{
						title: 'Šifra',
						dataIndex: 'product.sku',
						key: 'product.sku',
						sorter: (a, b) => {
							if (a.product.sku < b.product.sku) return -1;
							if (a.product.sku > b.product.sku) return 1;
							return 0;
						},
					},
					{
						title: 'Naziv',
						dataIndex: 'product.name',
						key: 'product.name',
						sorter: (a, b) => {
							if (a.product.name < b.product.name) return -1;
							if (a.product.name > b.product.name) return 1;
							return 0;
						},
					},
					{
						title: 'Količina',
						dataIndex: 'quantity',
						key: 'quantity',
					},
					{
						title: 'Cena',
						dataIndex: 'price',
						key: 'price',
					},
					{
						title: 'Datum dodavanja',
						dataIndex: 'date',
						key: 'date',
						render: text => (text ? moment(text).format('L') : null),
						sorter: (a, b) => (moment(a.date).isBefore(moment(b.date)) ? -1 : 1),
					},
					{
						title: 'Datum isteka',
						dataIndex: 'expirationDate',
						key: 'expirationDate',
						render: text => (text ? moment(text).format('L') : null),
						sorter: (a, b) => (moment(a.expirationDate).isBefore(moment(b.expirationDate)) ? -1 : 1),
					},
				]}
				properties={[]}
			>
				<CreateInventoryItemModal
					visible={this.state.createVisible}
					entity={this.state.inventoryItem}
					handleSave={() => {
						this.setState({ createVisible: false, inventoryItem: null });
					}}
					handleCancel={() => {
						this.setState({ createVisible: false, inventoryItem: null });
					}}
					convert={{}}
				/>
				<ImportInventoryItemsModal
					visible={this.state.importVisible}
					handleSave={() => {
						this.setState({ importVisible: false });
					}}
					handleCancel={() => {
						this.setState({ importVisible: false });
					}}
					convert={{}}
				/>
			</CrudPage>
		);
	}
}

function mapStateToProps(state) {
	return {
		isLoadingStores: state.stores.isFetching,
		isLoadingInvoices: state.invoices.isFetching,
		invoices: state.invoices.invoices,
		stores: state.stores.stores,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		fetchInventoryItems,
		createInventoryItem,
		fetchStores,
		deleteInventoryItem,
		updateInventoryItem,
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryItems);
