// @flow
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Input } from 'antd';

type Props = {
    record: any,
    sale: any,
}

@inject((stores, props) => ({
	amount: props.record.amount,
}))
@observer
export default class QuantityInput extends Component<Props> {
	render() {
		const { record, sale, amount } = this.props;
		return (
			<Input
				{...this.props}
				value={amount}
				onChange={(event: SyntheticKeyboardEvent<HTMLInputElement>) => {
					const { target: { value } } = event;
					sale.updateQuantity(record.id, parseFloat(value));
				}}
			/>
		);
	}
}
