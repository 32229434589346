// @flow
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import NumberInfo from 'ant-design-pro/lib/NumberInfo';
import numeral from 'numeral';
import SocketIo from 'socket.io-client';
import values from 'lodash/values';
import keyBy from 'lodash/keyBy';
import trim from 'lodash/trim';
import {
	Layout, List, Row, Col, Table, Card, Button, notification, Input, Popover, PageHeader, Tabs, Alert, Progress, Anchor,
} from 'antd';

import store from '../../../stores';
// import { PageHeader } from 'ant-design-pro';

import Sale from './sale';

import config from '../../../config.json';

const { Content } = Layout;
const { Item } = List;
const { Link } = Anchor;

type Props = {
	store: store,
};

type State = {
	height: number,
}

@inject('store')
@observer
class Calculations extends Component<Props, State> {
	state = {
		height: 0,
	}

	componentWillMount = () => {
		this.socket = new SocketIo(config.ecrservice);

		this.socket.on('connection', (socket) => {

		});
	}

	componentDidMount() {
		window.addEventListener('resize', this.updateDimensions);

		this.updateDimensions();
		this.props.store.productStore.fetchProducts();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensions);

		this.socket.close();
	}

	updateDimensions = () => {
		this.setState({ height: window.innerHeight - document.querySelector('.ant-page-header').clientHeight });
	}

	socket: SocketIo

	render() {
		const { store: { saleStore } } = this.props;
		const { height } = this.state;

		return (
			<div style={{
				flex: 1,
				display: 'flex',
				flexDirection: 'column',
			}}
			>
				<PageHeader
					breadcrumb={[
						{
							breadcrumbName: 'Početna',
							path: '/app',
						},
						{
							breadcrumbName: 'POS',
							path: '/app/pos',
						},
					]}
					title="POS"
					subTitle="Prodaja Artikala"
					extra={(
						<div style={{ marginTop: -7 }}>
							{/* <Alert message={<div><div style={{ float: 'left' }}>Sinhronizacija je u toku</div><Progress percent={30} size="small" style={{ width: 150, marginLeft: 8 }} /></div>} type="info" showIcon /> */}
							{/* <Alert message="Upozorenje: Problemi sa internet vezom" type="warning" showIcon /> */}
							{/* <Alert message={<span>Greška prilikom sinhronizacije. <Button size="small" style={{ marginTop: -1, marginBottom: -1 }}>Pokušaj ponovo</Button></span>} type="error" showIcon /> */}
						</div>
					)}
				/>
				<Content
					style={{
						flex: 1,
						display: 'flex',
						flexDirection: 'column',
						padding: '24px 0 0 0',
					}}
					id="posContent"
				>
					<Tabs
						className="posTabs"
						activeKey={`${saleStore.active}`}
						tabPosition="bottom"
						type="card"
						onChange={(key) => {
							saleStore.setActive(parseInt(key, 10));
						}}
						tabBarStyle={{ padding: '0 24px' }}
						tabBarExtraContent={(
							<Button
								onClick={() => {
									saleStore.createSale();
								}}
								type="primary"
								icon="plus"
							>
								Dodaj Prodaju
							</Button>
						)}
					>
						{saleStore.sales.map(sale => (
							<Tabs.TabPane
								style={{ padding: '0 24px' }}
								key={sale.id}
								tab={`Prodaja ${sale.id}`}
							>
								<Sale
									socket={this.socket}
									height={height}
									sale={sale}
									active={sale.id === saleStore.active}
								/>
							</Tabs.TabPane>
						))}
					</Tabs>

				</Content>

			</div>

		);
	}
}


export default Calculations;
