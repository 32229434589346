// @flow
import {
	types, flow, destroy, getParent, clone,
} from 'mobx-state-tree';

import forEach from 'lodash/forEach';
import round from 'lodash/round';
import localforage from 'localforage';
import { notification } from 'antd';
import { defaultClient, ecrServiceClient } from '../store/client';

import type { ProductType } from './Product';
import { Product } from './Product';

let retryInterval = null;

export const SaleItem = types
	.model('SaleItem', {
		id: types.identifierNumber,
		product: types.reference(Product),
		amount: types.number,
		price: types.number,
	});

export const Sale = types
	.model('Sale', {
		items: types.map(SaleItem),
		id: types.identifierNumber,
		payment: types.frozen({
			cash: types.number,
			check: types.number,
			card: types.number,
			change: types.number,
		}),
	})
	.actions((self) => {
		const addItem = function addItem(product: ProductType, amount) {
			if (self.items.has(product.id)) {
				const current = self.items.get(product.id);

				self.items.set(product.id, {
					...current,
					amount: current.amount + amount,
				});
			} else {
				self.items.put({
					id: product.id,
					price: (product.price || 0),
					product,
					amount,
				});
			}
		};

		const removeItem = function removeItem(productId) {
			if (self.items.has(productId)) {
				self.items.delete(productId);
			}
		};

		const updateQuantity = function updateQuantity(productId: number, amount: number) {
			if (self.items.has(productId)) {
				const product = self.items.get(productId);
				self.items.set(productId, {
					...product,
					amount,
				});
			}
		};

		const updatePayment = function updatePayment(cash, check, card) {
			const newCash = cash !== null ? cash || 0 : self.payment.cash || 0;
			const newCheck = check !== null ? check || 0 : self.payment.check || 0;
			const newCard = card !== null ? card || 0 : self.payment.card || 0;

			self.payment = {
				cash: newCash,
				check: newCheck,
				card: newCard,
				change: round((newCash + newCheck + newCard) - self.total, 2),
			};
		};

		const completeSale = flow(function* completeSale() {
			try {
				yield ecrServiceClient.post('/receipt/open');
				yield Promise.all(self.itemsAsArray.map(item => ecrServiceClient.post('/receipt/add', {
					sku: item.product.sku,
					quantity: item.amount,
					price: item.price,
				})));
				yield ecrServiceClient.post('/receipt/close');
			} catch (error) {
				return notification.error({
					key: 'saleError',
					message: 'Greška',
					description: 'Dogodila se greška u komunikaciji sa fiskalnom kasom. Proverite da li se kasa nalazi u režimu "PC VEZA"',
					duration: 10,
				});
			}

			try {
				const saleResponse = yield defaultClient.post('/sales', {
					timestamp: Date(),
					storeId: 2,
				});
				yield Promise.all(self.itemsAsArray.map(item => defaultClient.post(`/sales/${saleResponse.data.id}/items`, {
					sku: item.product.sku,
					sold: item.amount,
					total: item.price * item.amount,
				})));
			} catch (apiError) {
				getParent(getParent(self)).addToFailedQueue(clone(self));
				notification.error({
					key: 'saleWarning',
					message: 'Upozorenje',
					description: 'Dogodila se greška u komunikaciji sa serverom. Prodaja će biti sinhronizovana kasnije.',
					duration: 10,
				});
			}

			getParent(getParent(self)).removeSale(self);
		});

		return {
			completeSale,
			removeItem,
			addItem,
			updateQuantity,
			updatePayment,
		};
	})
	.views(self => ({
		get itemsAsArray() {
			return Array.from(self.items.values());
		},
		get total() {
			return self.itemsAsArray.reduce((prev, curr) => prev + (curr.amount || 0) * curr.price, 0);
		},
	}));

export const SaleStore = types
	.model('SalesStore', {
		active: types.number,
		sales: types.array(Sale),
		saleCount: types.number,
		fetched: types.boolean,
		loading: types.boolean,
		failedSales: types.array(Sale),
	})
	.actions((self) => {
		const createSale = function createSale() {
			self.saleCount += 1;
			const sale = Sale.create({
				items: {},
				id: self.saleCount,
				payment: {
					cash: 0,
					check: 0,
					card: 0,
					change: 0,
				},
			});

			self.sales.push(sale);
			self.active = self.active === 0 || self.sales.length === 1 ? self.saleCount : self.active;

			return sale;
		};

		const setActive = function setActive(id) {
			self.active = id;
		};

		const hydrate = flow(function* hydrate() {
			const stored = yield localforage.getItem('sales');
			if (!stored) {
				self.createSale();
			} else {
				forEach(stored, (value, key) => {
					self[key] = value;
				});
			}

			if (self.failedSales.length > 0) {
				if (!retryInterval) {
					retryInterval = setInterval(() => {
						self.retryFailed();
					}, 60000);
				}
			}
		});

		const removeSale = function removeSale(sale) {
			destroy(sale);
			if (self.sales[0]) {
				self.active = self.sales[0].id;
			} else {
				self.createSale();
			}
		};
		const removeFailed = function removeFailed(sale) {
			destroy(sale);
		};

		const addToFailedQueue = function addToFailedQueue(sale) {
			if (!retryInterval) {
				retryInterval = setInterval(() => {
					self.retryFailed();
				}, 60000);
			}
			self.failedSales.push(sale);
		};

		const retryFailed = flow(function* retryFailed() {
			yield Promise.all(self.failedSales
				.map(async (sale) => {
					try {
						const saleResponse = await defaultClient.post('/sales', {
							// TODO: use the sale date
							timestamp: Date(),
							storeId: 2,
						});

						await Promise.all(sale.itemsAsArray.map(item => defaultClient.post(`/sales/${saleResponse.data.id}/items`, {
							sku: item.product.sku,
							sold: item.amount,
							total: item.price * item.amount,
						})));

						self.removeFailed(sale);
					} catch (apiError) {
						console.log(apiError);
					}
				}));
			if (self.failedSales.length === 0) {
				clearInterval(retryInterval);
				notification.success({
					key: 'saleSync',
					message: 'Sinhronizacija uspešna',
					description: 'Prodaje su uspešno sinhronozovane sa serverom.',
					duration: 10,
				});
			}
		});


		return {
			createSale,
			hydrate,
			setActive,
			removeSale,
			addToFailedQueue,
			retryFailed,
			removeFailed,
		};
	});
