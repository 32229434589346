const initialState = {
	ledger: [],
};

export default function ledger(state = initialState, action) {
	switch (action.type) {
	case 'FETCH_LEDGER': {
		return {
			...state,
			isFetching: true,
		};
	}
	case 'FETCH_LEDGER_SUCCESS': {
		// TODO: Donos/Prenos
		return {
			...state,
			isFetching: false,
			ledger: action.payload.data.reduce((prev, curr, index) => {
				const fromPreviousPageIn = 0;
				const fromPreviousPageOut = 0;
				if (index === 0) {
					prev.push({
						type: 'previous',
						in: fromPreviousPageIn,
						out: fromPreviousPageOut,
					});
				}

				prev.push(curr);

				if (index > 0 && (index + 1) % 8 === 0) {
					prev.push({
						type: 'next',
						in: fromPreviousPageIn,
						out: fromPreviousPageOut,
					});
					prev.push({
						type: 'previous',
						in: fromPreviousPageIn,
						out: fromPreviousPageOut,
					});
				}

				return prev;
			}, []),
		};
	}
	case 'FETCH_LEDGER_FAIL': {
		return {
			...state,
			isFetching: false,
			error: action.error.response,
		};
	}
	default:
		return state;
	}
}
