// @flow
import {
	types, flow, destroy, getParent, clone,
} from 'mobx-state-tree';
import moment from 'moment';
import forEach from 'lodash/forEach';
import round from 'lodash/round';
import localforage from 'localforage';
import { notification } from 'antd';
import { applySnapshot } from 'mobx-state-tree';
import { defaultClient, ecrServiceClient } from '../store/client';

import { Product } from './Product';

export const StockCheckupItem = types
	.model('StockCheckupItem', {
		id: types.identifierNumber,
		product: Product,
		quantity: types.number,
		netPrice: types.number,
		grossPrice: types.number,
		tax: types.number,
	})
	.preProcessSnapshot((snapshot) => {
		const tax = Math.round((snapshot.price * Number(snapshot.product.tax) / (100 + Number(snapshot.product.tax))) * 100) / 100;
		return {
			...snapshot,
			grossPrice: snapshot.price,
			netPrice: snapshot.price - tax,
			tax,
		};
	});

export const StockCheckup = types
	.model('StockCheckup', {
		stockCheckupItems: types.array(StockCheckupItem),
		id: types.identifierNumber,
		number: types.number,
		timestamp: types.Date,
		processed: false,
		loading: false,
	})
	.preProcessSnapshot(snapshot => ({
		...snapshot,
		timestamp: moment(snapshot.timestamp).toDate(),
	}))
	.actions(self => ({
		update: flow(function* update(data) {
			self.creating = true;

			const checkup = yield defaultClient.put(`/stockCheckups/${self.id}`, {
				...data,
			});

			self.creating = false;
			applySnapshot(self, checkup.data);
		}),
		import: flow(function* importItems(data) {
			self.creating = true;
			const checkupItems = yield defaultClient.post(`/stockCheckups/${self.id}/items/import`, {
				data,
			});

			self.stockCheckupItems.push(...checkupItems.data);
			self.creating = false;
		}),
		process: flow(function* process() {
			self.loading = true;
			const checkup = yield defaultClient.post(`/stockCheckups/${self.id}/process`);
			applySnapshot(self, checkup.data);
			self.loading = false;
		}),
		destroy: flow(function* destroyItem() {
			self.creating = true;
			yield defaultClient.delete(`/stockCheckups/${self.id}`);
			getParent(getParent(self)).destroyChild(self);
			self.creating = false;
		}),
	}))
	.views(self => ({

	}));

export const StockCheckupStore = types
	.model('StockCheckupStore', {
		items: types.array(StockCheckup),
		fetched: types.boolean,
		loading: types.boolean,
		creating: types.boolean,
	})
	.actions(self => ({
		fetch: flow(function* fetch() {
			const checkups = yield defaultClient.get('/stockCheckups');
			self.items.replace(checkups.data);
		}),

		create: flow(function* create(data) {
			self.creating = true;

			const checkup = yield defaultClient.post('/stockCheckups', {
				...data,
				storeId: 2,
			});

			self.creating = false;
			self.items.push(checkup.data);
		}),

		destroyChild: (child) => {
			destroy(child);
		},

		hydrate: flow(function* hydrate() {
			yield self.fetch();
		}),
	}));
