import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Row, Modal, Button, Form } from 'antd';

const FormItem = Form.Item;

class Create extends Component {
	constructor(props) {
		super(props);

		this.handleCancel = this.handleCancel.bind(this);
		this.handleSave = this.handleSave.bind(this);
	}

	handleCancel() {
		this.props.form.resetFields();
		this.props.handleCancel();
	}

	handleSave() {
		this.props.form.validateFields((err, values) => {
			if (!err) {
				this.props.handleSave({ ...values, ...{ id: this.props.entity ? this.props.entity.id : undefined } })
					.then(() => {
						this.props.form.resetFields();
					});
			}
		});
	}

	render() {
		if (!this.props.visible) {
			return null;
		}

		const { getFieldDecorator } = this.props.form;


		return (<Modal
			width={this.props.width}
			visible={this.props.visible}
			title={this.props.entity ? this.props.editTitle : this.props.createTitle}
			onCancel={this.handleCancel}
			footer={[
				<Button key="cancel" onClick={this.handleCancel}>Poništi</Button>,
				<Button key="save" type="primary" loading={this.props.isCreating} onClick={this.handleSave}>
					Sačuvaj
				</Button>,
			]}
		>
			<Form
				layout={this.props.formLayout}
			>
				{this.props.properties.map((property) => {
					switch (property.type) {
					case 'columns':
						return (<Row
							align="bottom"
							key={property.key}
							gutter={8}
						>
							{property.columns.map(column => (
								<Col
									span={column.span}
									key={column.property}
								>
									<FormItem
										labelCol={column.labelCol || { span: 24 }}
										wrapperCol={column.wrapperCol || { span: 24 }}
										label={column.label}
										colon={column.colon}
										{...this.props.formItemProps}
									>
										{getFieldDecorator(column.property, {
											rules: column.rules,
											initialValue: column.initialValue,
										})(
											column.component
										)}
									</FormItem>
								</Col>))}
						</Row>);
					default:
						return (<FormItem
							labelCol={property.labelCol || { span: 24 }}
							wrapperCol={property.wrapperCol || { span: 24 }}
							label={property.label}
							colon={property.colon}
							key={property.property}
							{...this.props.formItemProps}
						>
							{getFieldDecorator(property.property, {
								rules: property.rules,
								initialValue: property.initialValue,
							})(
								property.component
							)}
						</FormItem>);
					}
				})}
			</Form>
		</Modal>);
	}
}

Create.propTypes = {
	context: PropTypes.func,
	formItemProps: PropTypes.shape({}),
	formLayout: PropTypes.string,
	visible: PropTypes.bool.isRequired,
	isCreating: PropTypes.bool.isRequired,
	handleCancel: PropTypes.func.isRequired,
	handleChange: PropTypes.func,
	handleSave: PropTypes.func.isRequired,
	entity: PropTypes.shape({
		id: PropTypes.number,
	}),

	createTitle: PropTypes.string.isRequired,
	editTitle: PropTypes.string.isRequired,

	properties: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			property: PropTypes.string,
			rules: PropTypes.array,
			component: PropTypes.node,

		})
	).isRequired,
	width: PropTypes.number,
};

Create.defaultProps = {
	width: 700,
	context: null,
	formItemProps: {},
	entity: null,
	formLayout: 'horizontal',
	handleChange: null,
};

function mapStateToProps(state, ownProps) {
	return {
		isCreating: state[ownProps.stateProperty].isCreating,
	};
}

function mapPropsToFields(props) {
	if (!props.entity) {
		return {};
	}

	return Object.keys(props.entity).reduce((prev, curr) => {
		prev[curr] = Form.createFormField({
			value: props.convert[curr] ? props.convert[curr](props.entity[curr]) : props.entity[curr],
		});
		return prev;
	}, {});
}

function onValuesChange(props, values) {
	if (props.handleChange) {
		props.handleChange.call(props.context, values);
	}
}

export default connect(mapStateToProps)(Form.create({ mapPropsToFields, onValuesChange })(Create));
