import { types, onSnapshot } from 'mobx-state-tree';
import localforage from 'localforage';
import { ProductStore } from './Product';
import { SaleStore } from './Sale';
import { PriceChangeStore } from './PriceChange';
import { StockCheckupStore } from './StockCheckup';

let hydrated = false;

export const Store = types
	.model('Store', {
		productStore: types.optional(ProductStore, {
			products: [],
			fetched: false,
			loading: false,
		}),
		saleStore: types.optional(SaleStore, {
			active: 0,
			saleCount: 0,
			fetched: false,
			loading: false,
		}),
		priceChangeStore: types.optional(PriceChangeStore, {
			priceChanges: [],
			fetched: false,
			loading: false,
		}),
		stockCheckupStore: types.optional(StockCheckupStore, {
			stockCheckups: [],
			fetched: false,
			loading: false,
			creating: false,
		}),
	})
	.actions((self) => {
		function afterCreate() {
			setTimeout(() => {
				const stores = Object.keys(self);
				const promises = [];
				stores.forEach((s) => {
					if (self[s].hydrate) {
						promises.push(self[s].hydrate());
					}
				});
				Promise.all(promises)
					.then(() => {
						hydrated = true;
					});
			});
		}

		return {
			afterCreate,
		};
	});

const store = Store.create();
onSnapshot(store, (snapshot) => {
	if (hydrated) {
		localforage.setItem('sales', snapshot.saleStore);
		localforage.setItem('products', snapshot.productStore);
		localforage.setItem('priceChanges', snapshot.priceChangeStore);
	}
});


export default store;
