import axios from 'axios';

import config from '../config.json';

export const defaultClient = axios.create({
	baseURL: config.api,
	responseType: 'json',

});

export const ecrServiceClient = axios.create({
	baseURL: config.ecrservice,
	responseType: 'json',
});

export default {};
