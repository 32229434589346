import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import auth from './auth';
import distributors from './distributors';
import stores from './stores';
import brands from './brands';
import invoices from './invoices';
import inventoryItems from './inventoryItems';
import calculations from './calculations';
import pricechanges from './pricechanges';
import products from './products';
import sales from './sales';
import search from './search';
import ledger from './ledger';
import users from './users';


export default combineReducers({
	auth,
	distributors,
	stores,
	brands,
	invoices,
	inventoryItems,
	calculations,
	products,
	sales,
	search,
	ledger,
	routerReducer,
	pricechanges,
	users,
});
