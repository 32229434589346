import { handleActions } from 'redux-actions';

import {
	FETCH_DISTRIBUTORS_ATTEMPT,
	FETCH_DISTRIBUTORS_FAILED,
	FETCH_DISTRIBUTORS_SUCCESS,
	CREATE_DISTRIBUTORS_ATTEMPT,
	CREATE_DISTRIBUTORS_FAILED,
	CREATE_DISTRIBUTORS_SUCCESS,
	UPDATE_DISTRIBUTORS_ATTEMPT,
	UPDATE_DISTRIBUTORS_FAILED,
	UPDATE_DISTRIBUTORS_SUCCESS,
	DELETE_DISTRIBUTORS_ATTEMPT,
	DELETE_DISTRIBUTORS_FAILED,
	DELETE_DISTRIBUTORS_SUCCESS,
} from '../actions/types';

const defaultState = {
	isFetching: false,
	isCreating: false,
	isDeleting: false,
	error: null,
	distributors: [],
};

const distributors = handleActions({
	[FETCH_DISTRIBUTORS_ATTEMPT]: state => ({
		...state,
		isFetching: true,
	}),
	[FETCH_DISTRIBUTORS_FAILED]: (state, action) => ({
		...state,
		isFetching: false,
		error: action.payload,
	}),
	[FETCH_DISTRIBUTORS_SUCCESS]: (state, action) => ({
		...state,
		isFetching: false,
		error: null,
		distributors: action.payload,
	}),
	[CREATE_DISTRIBUTORS_ATTEMPT]: state => ({
		...state,
		isCreating: true,
	}),
	[CREATE_DISTRIBUTORS_FAILED]: (state, action) => ({
		...state,
		isCreating: false,
		error: action.payload,
	}),
	[CREATE_DISTRIBUTORS_SUCCESS]: (state, action) => ({
		...state,
		isCreating: false,
		error: null,
		distributors: [...state.distributors, action.payload],
	}),
	[UPDATE_DISTRIBUTORS_ATTEMPT]: state => ({
		...state,
		isCreating: true,
	}),
	[UPDATE_DISTRIBUTORS_FAILED]: (state, action) => ({
		...state,
		isCreating: false,
		error: action.payload,
	}),
	[UPDATE_DISTRIBUTORS_SUCCESS]: (state, action) => ({
		...state,
		isCreating: false,
		error: null,
		distributors: [
			...state.distributors.map(
				distributor => (distributor.id === action.payload.id ? action.payload : distributor)
			)],
	}),
	[DELETE_DISTRIBUTORS_ATTEMPT]: state => ({
		...state,
		isDeleting: true,
	}),
	[DELETE_DISTRIBUTORS_FAILED]: (state, action) => ({
		...state,
		isDeleting: false,
		error: action.payload,
	}),
	[DELETE_DISTRIBUTORS_SUCCESS]: (state, action) => ({
		...state,
		isDeleting: false,
		error: null,
		distributors: [...state.distributors.filter(distributor => distributor.id !== action.payload)],
	}),

}, defaultState);

export default distributors;
