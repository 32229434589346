import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Row, Modal, Button, Form, AutoComplete, Input, Select, DatePicker, Alert } from 'antd';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import moment from 'moment';

import { createProduct, fetchProducts, updateProduct } from '../../../../../actions/products';
import { createItem, updateItem } from '../../../../../actions/pricechanges';

const FormItem = Form.Item;

class CreatePricechangeItemModal extends Component {
	constructor(props) {
		super(props);

		this.handleCancel = this.handleCancel.bind(this);
		this.handleSave = this.handleSave.bind(this);
	}

	state = {
		selectedProduct: null,
		product: {
			sku: '',
			name: '',
			price: '',
		},
		price: '',
	}

	componentDidMount() {
		this.props.fetchProducts();
	}

	componentWillReceiveProps(props) {
		if (!this.props.entity && props.entity) {
			this.selectProduct(props.entity.product, () => {
				this.handleChange({
					...props.entity,
				});
			}
			);
		}
	}

	getProperties = () => [
		...(this.state.selectedProduct ? [{
			type: 'columns',
			key: 'selectedProduct',
			columns: [
				{
					span: 24,
					property: 'selectedProductInfo',
					component: <Alert
						message={`Izabran je proizvod ${this.state.selectedProduct.sku} - ${this.state.selectedProduct.name}.`}
						banner
						type="info"
						closeText="Ukloni"
						style={{ margin: -24 }}
						showIcon={false}
						onClose={() => {
							this.resetProduct();
						}}
					/>,
				},
			],
		}] : []),
		{
			type: 'columns',
			key: 'productInfo',
			columns: [
				{
					span: 4,
					label: 'Šifra',
					property: 'product.sku',
					rules: [
						{ required: true, message: 'Šifra je obavezna' },
					],
					component: <AutoComplete
						defaultActiveFirstOption={false}
						filterOption={false}
						onSearch={this.handleSearchBySku}
						onSelect={(val, options) => {
							this.selectProduct(options.props.data);
						}}
						dropdownMatchSelectWidth={false}
						disabled={!!this.state.selectedProduct}
						dataSource={this.searchBySkuResults.map(d => <AutoComplete.Option key={`product.sku.autocomplete.${d.sku}`} data={d}>{`${d.sku} - ${d.name}`}</AutoComplete.Option>)}
					>
						<Input />
					</AutoComplete>,
				},
				{
					span: 7,
					label: 'Naziv',
					property: 'product.name',
					rules: [
						{ required: true, message: 'Naziv je obavezan' },
					],
					component: <AutoComplete
						defaultActiveFirstOption={false}
						filterOption={false}
						onSearch={this.handleSearchByName}
						onSelect={(val, options) => {
							this.selectProduct(options.props.data);
						}}
						dropdownMatchSelectWidth={false}
						disabled={!!this.state.selectedProduct}
						dataSource={this.searchByNameResults.map(d => <AutoComplete.Option key={`product.name.autocomplete.${d.sku}`} data={d}>{`${d.sku} - ${d.name}`}</AutoComplete.Option>)}
					>
						<Input />
					</AutoComplete>,
				},
				{
					span: 5,
					label: 'Stara cena',
					property: 'product.price',
					rules: [
						{ required: true, message: 'Stara cena je obavezna' },
					],
					component: <Input
						type="number"
						addonAfter="RSD"
						onChange={event => this.handleChange({ price: event.target.value })}
					/>,
				},
				{
					span: 5,
					label: 'Nova cena',
					property: 'price',
					rules: [
						{ required: true, message: 'Nova cena je obavezna' },
					],
					component: <Input
						type="number"
						addonAfter="RSD"
						onChange={event => this.handleChange({ price: event.target.value })}
					/>,
				},
				{
					span: 3,
					label: 'Količina',
					property: 'quantity',
					rules: [
						{ required: true, message: 'Količina je obavezna' },
					],
					component: <Input
						type="number"
					/>,
				},
			],
		},
	];

	selectProduct = (product, callback = () => {}) => {
		this.setState({
			selectedProduct: product,
		}, () => {
			debugger;
			this.handleChange({
				'product.sku': `${product.sku}`,
				'product.name': product.name,
				'product.price': product.price,
			});
			this.searchByNameResults = [];
			this.searchBySkuResults = [];
			this.props.form.setFields({
				'product.sku': { value: `${product.sku}` },
				'product.name': { value: product.name },
				'product.price': { value: product.price },
			});
			callback();
		});
	};

	resetProduct = () => {
		this.setState({
			selectedProduct: null,
		}, () => {
			this.props.form.setFields({
				'product.sku': { value: '' },
				'product.name': { value: '' },
				'product.price': { value: '' },
			});
		});
	}

	searchBySkuResults = [];
	searchByNameResults = [];

	handleSearchBySku = (value) => {
		this.searchBySkuResults = this.props.searchProductsBySku.search(value);
	}

	handleSearchByName = (value) => {
		this.searchByNameResults = this.props.searchProductsByName.search(value);
	}

	handleChange = (values) => {
		const currentState = {
			...this.state,
		};

		Object.keys(values).forEach((key) => {
			_.set(currentState, key, values[key]);
		});


		// if (values.price || values.price === '') {
		// 	currentState.price = values.price;
		// }
		// if (values.tax || values.tax === '') {
		// 	currentState.tax = values.tax;
		// }
		// if (values.finalPrice || values.finalPrice === '') {
		// 	currentState.finalPrice = values.finalPrice;
		// }
		// if (values.margin || values.margin === '') {
		// 	currentState.margin = values.margin;
		// }
		// if (values.rebates || values.rebates === '') {
		// 	values.rebates.forEach((rebate, index) => {
		// 		currentState.rebates[index] = rebate;
		// 	});
		// }

		this.setState(currentState);
	}


	handleCancel() {
		this.resetProduct();
		this.props.form.resetFields();
		this.props.handleCancel();
	}

	handleSave() {
		this.props.form.validateFields((err, values) => {
			if (!err) {
				if (!this.state.selectedProduct) {
					return Promise.reject({ error: 'Proizvod je obavezan' });
				}

				const itemFunc = this.props.entity ? this.props.updateItem : this.props.createItem;

				const pricechangeId = this.props.pricechange.id;

				const product = this.state.selectedProduct;
				return itemFunc({
					pricechangeId,
					productId: product.id,
					oldPrice: values.product.price,
					price: values.price,
					quantity: values.quantity,
					...(this.props.entity ? { id: this.props.entity.id } : {}),
				})
					.then(() => this.resetProduct())
					.then(() => this.props.handleSave())
					.then(() => this.props.form.resetFields());
			}

			return false;
		});
	}

	render() {
		if (!this.props.visible) {
			return null;
		}

		const { getFieldDecorator } = this.props.form;


		return (<Modal
			width={900}
			visible={this.props.visible}
			title={this.props.entity ? 'Izmena stavke nivelacije' : 'Dodavanje stavke nivelacije'}
			onCancel={this.handleCancel}
			footer={[
				<Button key="cancel" onClick={this.handleCancel}>Poništi</Button>,
				<Button key="save" type="primary" loading={this.props.isCreating} onClick={this.handleSave}>
					Sačuvaj
				</Button>,
			]}
		>
			<Form
				layout={this.props.formLayout}
			>
				{this.getProperties().map((property) => {
					switch (property.type) {
					case 'columns':
						return (<Row
							align="bottom"
							key={property.key}
							gutter={8}
						>
							{property.columns.map(column => (
								<Col
									span={column.span}
									key={column.property}
								>
									<FormItem
										labelCol={column.labelCol || { span: 24 }}
										wrapperCol={column.wrapperCol || { span: 24 }}
										label={column.label}
										colon={column.colon}
										{...this.props.formItemProps}
									>
										{getFieldDecorator(column.property, {
											rules: column.rules,
											initialValue: column.initialValue,
										})(
											column.component
										)}
									</FormItem>
								</Col>))}
						</Row>);
					default:
						return (<FormItem
							labelCol={property.labelCol || { span: 24 }}
							wrapperCol={property.wrapperCol || { span: 24 }}
							label={property.label}
							colon={property.colon}
							key={property.property}
							{...this.props.formItemProps}
						>
							{getFieldDecorator(property.property, {
								rules: property.rules,
								initialValue: property.initialValue,
							})(
								property.component
							)}
						</FormItem>);
					}
				})}
			</Form>
		</Modal>);
	}
}

CreatePricechangeItemModal.propTypes = {
	formItemProps: PropTypes.shape({}),
	formLayout: PropTypes.string,
	visible: PropTypes.bool.isRequired,
	isCreating: PropTypes.bool.isRequired,
	handleCancel: PropTypes.func.isRequired,
	handleSave: PropTypes.func.isRequired,


	createItem: PropTypes.func.isRequired,
	updateItem: PropTypes.func.isRequired,
	createProduct: PropTypes.func.isRequired,
	fetchProducts: PropTypes.func.isRequired,
	updateProduct: PropTypes.func.isRequired,

	entity: PropTypes.shape({
		id: PropTypes.number,
		product: PropTypes.shape({}),
	}),

	pricechange: PropTypes.shape({
		id: PropTypes.number,
	}).isRequired,
	searchProductsBySku: PropTypes.func.isRequired,
	searchProductsByName: PropTypes.func.isRequired,
	convert: PropTypes.shape({}),
};

CreatePricechangeItemModal.defaultProps = {
	width: 700,
	context: null,
	formItemProps: {},
	entity: null,
	formLayout: 'horizontal',
	handleChange: null,
	handleSave: () => {},
	convert: {},
};

function mapStateToProps(state) {
	return {
		isCreating: state.pricechanges.isCreating,
		searchProductsBySku: state.search.productBySku,
		searchProductsByName: state.search.productByName,

	};
}

function mapPropsToFields(props) {
	if (!props.entity) {
		return {};
	}

	return {
		..._.mapValues(props.entity, entity => (Form.createFormField({ value: entity }))),
		product: {
			..._.mapValues(props.entity.product, product => (Form.createFormField({ value: product }))),
		},
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		createProduct,
		updateProduct,
		fetchProducts,
		createItem,
		updateItem,
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ mapPropsToFields })(CreatePricechangeItemModal));
