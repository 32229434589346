import React, { Component } from 'react';
import {
	withRouter, Route, Switch, Link,
} from 'react-router-dom';
import { Layout, Menu, Icon } from 'antd';

import ApplicationDistributors from './Distributors';
import ApplicationStores from './Stores';
import ApplicationLedger from './Ledger';

import ApplicationUsers from './Users';
import ApplicationProductBrands from './Products/Brands';
import ApplicationProductSales from './Products/Sales';
import ApplicationProductInvoices from './Products/Invoices';
import ApplicationProductCalculations from './Products/Calculations';
import ApplicationProductProducts from './Products/Products';
import ApplicationProductPricechanges from './Products/Pricechanges';
import ApplicationProductInventory from './Products/Inventory';
import StockCheckups from './Products/StockCheckups';
import ApplicationPOS from './POS';

const { Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;

class Application extends Component {
	constructor(props) {
		super(props);
		this.state = {
			collapsed: false,
		};
		this.onCollapse = this.onCollapse.bind(this);
	}

	onCollapse(collapsed) {
		this.setState({ collapsed });
	}

	render() {
		return (
			<Layout style={{ minHeight: '100vh' }}>
				<Sider
					collapsible
					collapsed={this.state.collapsed}
					onCollapse={this.onCollapse}
				>
					<div className="logo" />
					<Menu theme="dark" defaultSelectedKeys={['pos']} mode="inline">

						<Menu.Item key="pos">
							<Link
								to="/app/pos"
							>
								<Icon type="calculator" />
								<span>POS</span>
							</Link>
						</Menu.Item>
						<Menu.Item key="1">
							<Icon type="desktop" />
							<span>Kontrolna Tabla</span>
						</Menu.Item>
						<Menu.Item key="2">
							<Link
								to="/app/distributors"
							>
								<Icon type="car" />
								<span>Distributeri</span>
							</Link>
						</Menu.Item>
						<Menu.Item key="3">
							<Link
								to="/app/stores"
							>
								<Icon type="shop" />
								<span>Prodavnice</span>
							</Link>
						</Menu.Item>
						<Menu.Item key="ledger">
							<Link
								to="/app/ledger"
							>
								<Icon type="file-text" />
								<span>KEP Knjiga</span>
							</Link>
						</Menu.Item>
						<SubMenu
							key="sub1"
							title={<span><Icon type="tag-o" /><span>Roba</span></span>}
						>
							<Menu.Item key="4">
								<Link
									to="/app/products/brands"
								>
									Brendovi
								</Link>
							</Menu.Item>
							<Menu.Item key="5">
								<Link
									to="/app/products/invoices"
								>
									Fakture
								</Link>
							</Menu.Item>
							<Menu.Item key="6">
								<Link
									to="/app/products/calculations"
								>
									Kalkulacije
								</Link>
							</Menu.Item>
							<Menu.Item key="pricechanges">
								<Link
									to="/app/products/pricechanges"
								>
									Nivelacije
								</Link>
							</Menu.Item>
							<Menu.Item key="7">
								<Link
									to="/app/products/products"
								>
									Proizvodi
								</Link>
							</Menu.Item>
							<Menu.Item key="8">
								<Link
									to="/app/products/inventory"
								>
									Inventar
								</Link>
							</Menu.Item>
							<Menu.Item key="9">
								<Link
									to="/app/products/sales"
								>
									Prodaje
								</Link>
							</Menu.Item>
							<Menu.Item key="10">
								<Link
									to="/app/products/stockCheckups"
								>
									Popisi
								</Link>
							</Menu.Item>
						</SubMenu>
						<Menu.Item key="users">
							<Link
								to="/app/users"
							>
								<Icon type="user" />
								<span>Korisnici</span>
							</Link>
						</Menu.Item>
					</Menu>
				</Sider>
				<Layout>
					<Switch>
						<Route path="/app/pos" component={ApplicationPOS} />
						<Route path="/app/distributors" component={ApplicationDistributors} />
						<Route path="/app/stores" component={ApplicationStores} />
						<Route path="/app/users" component={ApplicationUsers} />
						<Route path="/app/ledger" component={ApplicationLedger} />
						<Route path="/app/products/brands" component={ApplicationProductBrands} />
						<Route path="/app/products/invoices" component={ApplicationProductInvoices} />
						<Route path="/app/products/calculations" component={ApplicationProductCalculations} />
						<Route path="/app/products/products" component={ApplicationProductProducts} />
						<Route path="/app/products/pricechanges" component={ApplicationProductPricechanges} />
						<Route path="/app/products/inventory" component={ApplicationProductInventory} />
						<Route path="/app/products/sales" component={ApplicationProductSales} />
						<Route path="/app/products/stockCheckups" component={StockCheckups} />
					</Switch>
					{/* <Footer style={{ textAlign: 'center' }}>
						Copyright &copy; 2019 Pet Shop Moja Mačkica | <a href="https://icons8.com/android-L">Free icons by Icons8</a>
					</Footer> */}
				</Layout>
			</Layout>
		);
	}
}
export default withRouter(Application);


// <Header style={{ background: '#fff', padding: 0 }} />
// 	<Content style={{ margin: '0 16px' }}>
// 		<Breadcrumb style={{ margin: '12px 0' }}>
// 			<Breadcrumb.Item>User</Breadcrumb.Item>
// 			<Breadcrumb.Item>Bill</Breadcrumb.Item>
// 		</Breadcrumb>
// 		<div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
// 			Bill is a cat.
// 						</div>
// 	</Content>
