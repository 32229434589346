import { handleActions } from 'redux-actions';

import { LOGIN_ATTEMPT, LOGIN_FAILED, LOGIN_SUCCESS } from '../actions/types';

const defaultState = {
	isLoading: false,
	error: null,
	auth: null,
	isAuthenticated: false,
};

const auth = handleActions({
	[LOGIN_ATTEMPT]: state => ({
		...state,
		isLoading: true,
	}),
	[LOGIN_FAILED]: (state, action) => ({
		...state,
		isLoading: false,
		error: action.error.response,
		isAuthenticated: false,
	}),
	LOGOUT_SUCCESS: state => ({
		...state,
		isLoading: false,
		isAuthenticated: false,
	}),
	[LOGIN_SUCCESS]: (state, action) => ({
		...state,
		isLoading: false,
		error: null,
		auth: action.payload,
		isAuthenticated: true,
	}),

}, defaultState);

export default auth;
