import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import sortBy from 'lodash/sortBy';
import {
	Input, Button, Table, Select, DatePicker, Spin, Icon, Popconfirm, Row, Col, Layout,
} from 'antd';
import print from 'print-html-element';
import nl2br from 'react-newline-to-break';
import PageHeader from 'ant-design-pro/lib/PageHeader';
import Result from 'ant-design-pro/lib/Result';


import moment from 'moment';

import {
	processCalculation, fetchCalculations, createCalculation, deleteCalculation, updateCalculation, createItem, updateItem, deleteItem,
} from '../../../../actions/calculations';
import { fetchStores } from '../../../../actions/stores';
import { fetchDistributors } from '../../../../actions/distributors';
import { fetchInvoices } from '../../../../actions/invoices';
import CrudPage from '../../../../components/CrudPage';
import CreateCalculationItemModal from './CreateCalculationItemModal';
import CreateCalculation from './CreateCalculation';

import numberFormat from '../../../../lib/numberFormat';

const { Header, Content } = Layout;

class Calculations extends Component {
	static propTypes = {
		fetchInvoices: PropTypes.func.isRequired,
		processCalculation: PropTypes.func.isRequired,
		fetchDistributors: PropTypes.func.isRequired,
		updateItem: PropTypes.func.isRequired,
		createItem: PropTypes.func.isRequired,
		deleteItem: PropTypes.func.isRequired,
		fetchStores: PropTypes.func.isRequired,
		fetchCalculations: PropTypes.func.isRequired,
		deleteCalculation: PropTypes.func.isRequired,
		isLoading: PropTypes.bool.isRequired,
		isLoadingDistributors: PropTypes.bool.isRequired,
		isLoadingStores: PropTypes.bool.isRequired,
		isLoadingInvoices: PropTypes.bool.isRequired,
		stores: PropTypes.arrayOf(
			PropTypes.shape({})
		).isRequired,
		invoices: PropTypes.arrayOf(
			PropTypes.shape({})
		).isRequired,
		calculations: PropTypes.shape({}),
		distributors: PropTypes.arrayOf(
			PropTypes.shape({})
		).isRequired,
		isFetched: PropTypes.bool.isRequired,
	}

	static defaultProps = {
		calculations: {},
	}

	state = {
		createVisible: false,
		createCalculationVisible: false,
		calculationItem: null,
		invoices: [],
		selectedYear: ['2022'],//[moment().format('YYYY')],
	}

	calcRefs = {}

	componentDidMount() {
		this.props.fetchInvoices();
		this.props.fetchDistributors();
		this.props.fetchStores();
		if (!this.props.isFetched) {
			this.props.fetchCalculations();
		}
	}

	editRender(property, editComponent, render) {
		return (text, record) => (render ? render(text, record) : text)
		// }

		// return cloneElement(editComponent, {
		// 	onChange: (event) => {
		// 		const newRecord = {
		// 			...record,
		// 		};
		// 		if (event.currentTarget) {
		// 			return this.props.updateItem(_.set(newRecord, property, event.currentTarget.value));
		// 		}

		// 		return this.props.updateItem(_.set(newRecord, property, event));
		// 	},
		// 	defaultValue: text,
		// });
		;
	}

	expandedRowRender(data) {
		let totalPrice = 0;
		let totalPriceWithRebate = 0;
		let totalPriceWithTaxes = 0;
		let taxesAmount = 0;
		let totalFinalPriceBeforeTax = 0;
		const taxes = {};
		const taxesRows = [];
		const taxesDetails = {};
		const taxesDetailsRows = [];

		data.calculationItems.forEach((item) => {
			totalPrice += item.price * item.quantity;
			totalPriceWithRebate += item.priceWithRebate * item.quantity;
			totalPriceWithTaxes += (item.priceWithRebate * item.quantity) * (1 + (item.tax / 100));
			totalFinalPriceBeforeTax += item.finalPriceBeforeTax * item.quantity;

			taxes[item.tax] = taxes[item.tax] || 0;
			taxes[item.tax] += item.priceWithRebate * item.quantity * (item.tax / 100);
			taxesAmount += (item.taxAmount * item.quantity);

			taxesDetails[item.tax] = taxesDetails[item.tax] || {
				priceWithoutTax: 0,
				difference: 0,
				finalPriceBeforeTax: 0,
				tax: 0,
				finalPrice: 0,
			};

			taxesDetails[item.tax].priceWithoutTax += item.priceWithRebate * item.quantity;
			taxesDetails[item.tax].finalPriceBeforeTax += item.finalPriceBeforeTax * item.quantity;
			taxesDetails[item.tax].tax += item.taxAmount * item.quantity;
			taxesDetails[item.tax].finalPrice += item.finalPrice * item.quantity;
			taxesDetails[item.tax].difference = taxesDetails[item.tax].finalPriceBeforeTax - taxesDetails[item.tax].priceWithoutTax;
		});

		Object.keys(taxes).forEach((tax) => {
			taxesRows.push({
				title: `Porez ${numberFormat(tax)}%:`,
				amount: 0,
				taxAmount: `+${numberFormat(taxes[tax])}`,
				key: `tax${tax}`,
			});
		});

		Object.keys(taxesDetails).forEach((tax) => {
			taxesDetailsRows.push({
				key: `taxDetails${tax}`,
				title: `Porez na dodatu vrednost ${numberFormat(tax)}%:`,
				priceWithoutTax: numberFormat(taxesDetails[tax].priceWithoutTax),
				difference: numberFormat(taxesDetails[tax].difference),
				finalPriceBeforeTax: numberFormat(taxesDetails[tax].finalPriceBeforeTax),
				tax: numberFormat(taxesDetails[tax].tax),
				finalPrice: numberFormat(taxesDetails[tax].finalPrice),
			});
		});
		const totals = Object.keys(taxesDetails).reduce((prev, curr) => {
			prev.priceWithoutTax += taxesDetails[curr].priceWithoutTax;
			prev.difference += taxesDetails[curr].difference;
			prev.finalPriceBeforeTax += taxesDetails[curr].finalPriceBeforeTax;
			prev.tax += taxesDetails[curr].tax;
			prev.finalPrice += taxesDetails[curr].finalPrice;
			return prev;
		}, {
			priceWithoutTax: 0,
			difference: 0,
			finalPriceBeforeTax: 0,
			tax: 0,
			finalPrice: 0,
		});

		taxesDetailsRows.push(
			{
				key: 'taxDetailsTotal',
				title: 'Ukupno:',
				priceWithoutTax: numberFormat(totals.priceWithoutTax),
				difference: numberFormat(totals.difference),
				finalPriceBeforeTax: numberFormat(totals.finalPriceBeforeTax),
				tax: numberFormat(totals.tax),
				finalPrice: numberFormat(totals.finalPrice),
			}
		);
		// TODO: Add company and distributor details
		// TODO: Add company info to the database
		return (
			<div
				className="bo-calculation"
				ref={(el) => { this.calcRefs[data.id] = el; }}
			>
				<div className="bo-calculation-info-print">
					<Row gutter={8}>
						<Col span={8}>
							<strong>Pet Shop Moja Mačkica</strong>
							<br />
							Bulevar Kralja Aleksandra 195/2, Beograd
							<br />
							PIB: 110228902
							<br />
							Prodavnica: {data.store.name} {data.store.address}
						</Col>
						<Col span={8}>
							<strong>Distributer:</strong>
							{' '}
							{data.invoice.distributor.name}
							<br />
							{nl2br(data.invoice.distributor.address)}
						</Col>
						<Col span={8}>
							<strong>Ulaz. dok.:</strong>
							{data.number}
							/
							{moment(data.date).format('L')}
							<br />
							<strong>Račun:</strong>
							{data.invoice.number}
							/
							{moment(data.invoice.date).format('L')}

						</Col>
					</Row>
					<h2>
						Maloprodajna kalkulacija broj {data.number} od {moment(data.date).format('L')}
					</h2>
				</div>
				<Table
					footer={() => [
						<Button
							onClick={() => this.setState({ createVisible: true, calculation: data })}
							icon="plus"
							style={{ marginRight: 8 }}
							key="addButton"
						>
							Dodaj novu stavku
						</Button>,
						<Button
							icon="printer"
							onClick={() => {
								print.printElement(findDOMNode(this.calcRefs[data.id]));
							}}
							key="printButton"
						>
							Štampaj
						</Button>,
					]
					}
					pagination={false}
					size="middle"
					bordered
					rowKey={record => record.id || record.tmpId}
					dataSource={sortBy(data.calculationItems, 'id')}
					columns={[
						{
							title: '#',
							key: 'index',
							width: '80px',
							render(text, record, index) {
								return index + 1;
							},
						},
						{
							title: 'Šifra',
							key: 'product.sku',
							dataIndex: 'product.sku',
							width: '70px',

						},
						{
							title: 'Naziv',
							key: 'product.name',
							dataIndex: 'product.name',
						},
						{
							title: 'Nabavna cena',
							key: 'priceWithRebate',
							dataIndex: 'priceWithRebate',
							width: '100px',
							render: text => numberFormat(text),
						},
						{
							title: 'Količina',
							key: 'quantity',
							dataIndex: 'quantity',
							width: '70px',
						},
						{
							title: 'JM',
							key: 'unit',
							dataIndex: 'unit',
							width: '80px',
						},
						{
							title: 'Razlika u ceni',
							key: 'priceDifference',
							dataIndex: 'priceDifference',
							width: '120px',
							render: text => numberFormat(text),
						},
						{
							title: 'Prodajna cena b.p.',
							key: 'finalPriceBeforeTax',
							dataIndex: 'finalPriceBeforeTax',
							width: '130px',
							render: text => numberFormat(text),
						},
						{
							title: 'PDV',
							key: 'tax',
							dataIndex: 'tax',
							width: '50px',
							render: text => numberFormat(text),
						},
						{
							title: 'Iznos PDV',
							key: 'taxAmount',
							dataIndex: 'taxAmount',
							width: '80px',
							render: text => numberFormat(text),
						},
						{
							title: 'Prodajna cena',
							key: 'finalPrice',
							dataIndex: 'finalPrice',
							width: '120px',
							render: text => numberFormat(text),
						},
						{
							title: 'Akcije',
							key: 'actions',
							className: 'bo-column-actions',
							width: '140px',
							render: (text, record) => (
								<Button.Group>
									<Button
										onClick={() => {
											this.setState({
												calculationItem: record,
												createVisible: true,
												calculation: data,
											});
										}}
									>
										Izmeni


										{' '}
									</Button>
									<Popconfirm
										placement="topRight"
										title="Da li ste sigurni da želite da obrišete ovu stavku?"
										onConfirm={() => {
											this.props.deleteItem(record);
										}}
										okText="Da"
										cancelText="Ne"
									>
										<Button icon="delete" />
									</Popconfirm>
								</Button.Group>
							),
						},

					]}
				/>
				<Row
					className="bo-calculation-summary-container"
					gutter={8}
					style={{ marginBottom: -1 }}
				>
					<Col
						className="bo-calculation-summary-col1"
						span={6}
					>
						<Table
							className="bo-calculation-summary"
							rowClassName={record => record.className || ''}
							pagination={false}
							dataSource={[
								{
									title: 'Nabavna vrednost:',
									amount: numberFormat(totalPrice),
									taxAmount: '',
									key: 'totalPrice',
								},
								{
									title: 'Rabat:',
									amount: numberFormat(totalPriceWithRebate - totalPrice),
									taxAmount: '',
									key: 'totalRebate',
								},
								...taxesRows,
								{
									title: 'Ukupna nabavna vrednost:',
									amount: numberFormat(totalPriceWithTaxes),
									taxAmount: '',
									key: 'totalWithRebateAndTaxes',
									className: 'bo-top-border',
								},
								{
									title: 'Vrednost bez PDV / PDV:',
									amount: numberFormat(totalPriceWithRebate),
									taxAmount: numberFormat(taxesAmount),
									key: 'totalPriceWithoutTaxAndTax',
								},
								{
									title: 'Razlika u ceni:',
									amount: numberFormat(totalFinalPriceBeforeTax - totalPriceWithRebate),
									taxAmount: '',
									key: 'priceDifference',
								},
								{
									title: 'Prodajna vrednost bez PDV:',
									amount: numberFormat(totalFinalPriceBeforeTax),
									taxAmount: '',
									key: 'totalFinalPriceBeforeTax',
								},
							]}
							bordered
							size="middle"
							showHeader={false}
							columns={[
								{
									dataIndex: 'title',
								},
								{
									dataIndex: 'amount',
								},
								{
									dataIndex: 'taxAmount',
								},
							]}
						/>
					</Col>
					<Col
						className="bo-calculation-summary-col2"
						span={18}
					>
						<Table
							className="bo-calculation-details"
							bordered
							size="middle"
							pagination={false}
							dataSource={taxesDetailsRows}
							columns={[
								{
									title: '',
									dataIndex: 'title',
								},
								{
									title: 'Nabavna vrednost',
									dataIndex: 'priceWithoutTax',
								},
								{
									title: 'Razlika',
									dataIndex: 'difference',
								},
								{
									title: 'Prodajna vrednost bez PDV',
									dataIndex: 'finalPriceBeforeTax',
								},
								{
									title: 'PDV',
									dataIndex: 'tax',
								},
								{
									title: 'Maloprodajna vrednost',
									dataIndex: 'finalPrice',
								},
							]}
						/>
					</Col>
				</Row>
				<div className="bo-calculation-signature-print">
					Kalkulisao:


					<br />
					<br />
					_______________________


				</div>
			</div>
		);
	}

	render() {
		return (
			<div>

				<PageHeader
					breadcrumbList={[
						{
							title: 'Početna',
							href: '/app',
						},
						{
							title: 'Proizvodi',
							href: '/app/products',
						},
						{
							title: 'Kalkulacije',
						},
					]}
					title="Kalkulacije"
					action={(
						<div>
							<Button
								icon="plus"
								type="primary"
								onClick={() => {
									this.setState(() => ({
										createCalculationVisible: true,
									}));
								}}
							>
								Dodaj kalkulaciju


								{' '}
							</Button>
						</div>
					)}
				/>
				<Content>
					<Table
						dataSource={_.values(this.props.calculations)}
						loading={this.props.isLoading}
						expandedRowRender={data => this.expandedRowRender(data)}
						className="bo-nested-table"
						rowKey="id"
						onChange={(pagination, filters, sorter) => {
							this.setState({
								selectedYear: filters.date,
							});
						}}
						columns={[
							{
								title: 'Broj kalkulacije',
								dataIndex: 'number',
								key: 'number',
								sorter: (a, b) => {
									if (a.number < b.number) return -1;
									if (a.number > b.number) return 1;
									return 0;
								},
							},
							{
								title: 'Prodavnica',
								dataIndex: 'store.name',
								key: 'store.name',
								sorter: (a, b) => {
									if (a.store.name < b.store.name) return -1;
									if (a.store.name > b.store.name) return 1;
									return 0;
								},
								filters: this.props.stores.map(store => ({
									text: store.name,
									value: store.id,
								})),
								filterMultiple: true,
								onFilter: (value, record) => `${record.storeId}` === value,
							},
							{
								title: 'Distributer',
								dataIndex: 'invoice.distributor.name',
								key: 'invoice.distributor.name',
								sorter: (a, b) => {
									if (a.invoice.distributor.name < b.invoice.distributor.name) return -1;
									if (a.invoice.distributor.name > b.invoice.distributor.name) return 1;
									return 0;
								},
								filters: this.props.distributors.map(distributor => ({
									text: distributor.name,
									value: distributor.id,
								})),
								filterMultiple: true,
								onFilter: (value, record) => `${record.invoice.distributorId}` === value,
							},
							{
								title: 'Faktura',
								dataIndex: 'invoice.number',
								key: 'invoice.number',
							},
							{
								title: 'Datum kalkulacije',
								dataIndex: 'date',
								key: 'date',
								render: text => moment(text).format('L'),
								filters: [
									{
										text: '2017',
										value: '2017',
									},
									{
										text: '2018',
										value: '2018',
									},
									{
										text: '2019',
										value: '2019',
									},
									{
										text: '2020',
										value: '2020',
									},
									{
										text: '2021',
										value: '2021',
									},
{
text: '2022',
value: '2022',
}
								],
								filteredValue: this.state.selectedYear,
								filterMultiple: true,
								onFilter: (value, record) => moment(record.date).format('YYYY') === value,
							},
							{
								title: 'Proknjižena',
								dataIndex: 'processed',
								render: value => <span className={value ? 'bo-paid' : 'bo-not-paid'}>{value ? <Icon type="check" /> : <Icon type="close" />}</span>,
							},
							{
								title: 'Akcije',
								key: 'actions',
								className: 'bo-column-actions',
								render: (text, record) => (
									<Button.Group>
										<Popconfirm
											placement="topRight"
											title="Da li ste sigurni da želite da proknjižite ovu kalkulaciju?"
											onConfirm={() => {
												this.props.processCalculation(record);
											}}
											okText="Da"
											cancelText="Ne"
										>
											<Button icon="book" />
										</Popconfirm>
										<Button
											onClick={() => this.setState({
												calculation: record,
												createCalculationVisible: true,
											})}
											icon="edit"
										/>
										<Popconfirm
											placement="topRight"
											title="Da li ste sigurni da želite da obrišete ovu kalkulaciju?"
											onConfirm={() => {
												this.props.deleteCalculation(record.id);
											}}
											okText="Da"
											cancelText="Ne"
										>
											<Button icon="delete" />
										</Popconfirm>
									</Button.Group>
								),
							},
						]}
					/>
				</Content>
				<CreateCalculationItemModal
					visible={this.state.createVisible}
					calculation={this.state.calculation}
					entity={this.state.calculationItem}
					handleSave={() => {
						this.setState({ createVisible: false, calculationItem: null });
					}}
					handleCancel={() => {
						this.setState({ createVisible: false, calculationItem: null });
					}}
					convert={{}}
				/>
				<CreateCalculation
					visible={this.state.createCalculationVisible}
					entity={this.state.calculation}
					handleSave={() => {
						this.setState({ createCalculationVisible: false, calculation: null });
					}}
					handleCancel={() => {
						this.setState({ createCalculationVisible: false, calculation: null });
					}}
					convert={{}}
				/>
			</div>

		);


		// return (<CrudPage
		// 	tableProps={{
		// 		expandedRowRender: data => this.expandedRowRender(data),
		// 		className: 'bo-nested-table',
		// 	}}
		// 	fetchAction={fetchCalculations}
		// 	createAction={createCalculation}
		// 	deleteAction={deleteCalculation}
		// 	updateAction={updateCalculation}
		// 	useMap
		// 	createTitle="Dodaj Kalkulaciju"
		// 	editTitle="Izmeni Kalkulaciju"
		// 	addButtonText="Dodaj Kalkulaciju"
		// 	deleteButtonPrompt="Da li ste sigurni da želite da obrišete ovu kalkulaciju?"
		// 	stateProperty="calculations"
		// columns={[
		// 	{
		// 		title: 'Broj kalkulacije',
		// 		dataIndex: 'number',
		// 		key: 'number',
		// 		sorter: (a, b) => {
		// 			if (a.number < b.number) return -1;
		// 			if (a.number > b.number) return 1;
		// 			return 0;
		// 		},
		// 	},
		// 	{
		// 		title: 'Prodavnica',
		// 		dataIndex: 'store.name',
		// 		key: 'store.name',
		// 		sorter: (a, b) => {
		// 			if (a.store.name < b.store.name) return -1;
		// 			if (a.store.name > b.store.name) return 1;
		// 			return 0;
		// 		},
		// 		filters: this.props.stores.map(store => ({
		// 			text: store.name,
		// 			value: store.id,
		// 		})),
		// 		filterMultiple: true,
		// 		onFilter: (value, record) => `${record.storeId}` === value,
		// 	},
		// 	{
		// 		title: 'Distributer',
		// 		dataIndex: 'invoice.distributor.name',
		// 		key: 'invoice.distributor.name',
		// 		sorter: (a, b) => {
		// 			if (a.invoice.distributor.name < b.invoice.distributor.name) return -1;
		// 			if (a.invoice.distributor.name > b.invoice.distributor.name) return 1;
		// 			return 0;
		// 		},
		// 		filters: this.props.distributors.map(distributor => ({
		// 			text: distributor.name,
		// 			value: distributor.id,
		// 		})),
		// 		filterMultiple: true,
		// 		onFilter: (value, record) => `${record.invoice.distributorId}` === value,
		// 	},
		// 	{
		// 		title: 'Faktura',
		// 		dataIndex: 'invoice.number',
		// 		key: 'invoice.number',
		// 	},
		// 	{
		// 		title: 'Datum kalkulacije',
		// 		dataIndex: 'date',
		// 		key: 'date',
		// 		render: text => moment(text).format('L'),
		// 		filters: [
		// 			{
		// 				text: '2017',
		// 				value: '2017',
		// 			},
		// 			{
		// 				text: '2018',
		// 				value: '2018',
		// 			},
		// 		],
		// 		defaultFilteredValue: ['2018'],
		// 		filterMultiple: true,
		// 		onFilter: (value, record) => moment(record.date).format('YYYY') === value,
		// 	},
		// 	{
		// 		title: 'Proknjižena',
		// 		dataIndex: 'processed',
		// 		render: value => <span className={value ? 'bo-paid' : 'bo-not-paid'}>{value ? <Icon type="check" /> : <Icon type="close" />}</span>,
		// 	},
		// ]}
		// 	convert={{
		// 		date: moment,
		// 		storeId: storeId => `${storeId}`,
		// 		invoiceId: invoiceId => `${invoiceId}`,
		// 	}}
		// 	extraButtons={(text, record) => [
		// 		<Button
		// 			key="process"
		// 			onClick={() => {
		// 				this.props.processCalculation(record);
		// 			}}
		// 			icon="book"
		// 		/>,
		// 	]}
		// 	properties={[
		// {
		// 	label: 'Broj kalkulacije',
		// 	property: 'number',
		// 	rules: [],
		// 	component: <Input />,
		// },
		// 		{
		// 			label: 'Prodavnica',
		// 			property: 'storeId',
		// 			rules: [{
		// 				required: true, message: 'Prodavnica je obavezna',
		// 			}],
		// 			component: <Select
		// 				showSearch
		// 				onSelect={(value) => {
		// 					this.setState(() => ({
		// 						invoices: this.props.invoices.filter(invoice => invoice.storeId === parseInt(value, 10)),
		// 					}));
		// 				}}
		// 				filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
		// 			>
		// 				{this.props.isLoadingStores ? <Select.Option className="bo-select-loading" key="loading" disabled><Spin /></Select.Option> : null}
		// 				{this.props.stores.map(
		// 					store => <Select.Option key={store.id} value={`${store.id}`}>{store.name}</Select.Option>)
		// 				}
		// 			</Select>,
		// 		},
		// {
		// 	label: 'Faktura',
		// 	property: 'invoiceId',
		// 	rules: [{
		// 		required: true, message: 'Faktura je obavezna',
		// 	}],
		// 	component: <Select
		// 		showSearch
		// 		filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
		// 	>
		// 		{this.props.isLoadingInvoices ? <Select.Option className="bo-select-loading" key="loading" disabled><Spin /></Select.Option> : null}
		// 		{
		// 			this.state.invoices
		// 				.map(
		// 					invoice => <Select.Option key={invoice.id} value={`${invoice.id}`}>{invoice.number}</Select.Option>
		// 				)
		// 		}
		// 	</Select>,
		// },
		// {
		// 	label: 'Datum kalkulacije',
		// 	property: 'date',
		// 	rules: [{
		// 		required: true, message: 'Datum kalkulacije je obavezan',
		// 	}],
		// 	component: <DatePicker />,
		// },
		// 	]}
		// >
		// 	<CreateCalculationItemModal
		// 		visible={this.state.createVisible}
		// 		calculation={this.state.calculation}
		// 		entity={this.state.calculationItem}
		// 		handleSave={() => {
		// 			this.setState({ createVisible: false, calculationItem: null });
		// 		}}
		// 		handleCancel={() => {
		// 			this.setState({ createVisible: false, calculationItem: null });
		// 		}}
		// 		convert={{}}
		// 	/>
		// </CrudPage>);
	}
}

function mapStateToProps(state) {
	return {
		isFetched: state.calculations.isFetched,
		isLoading: state.stores.isFetching || state.invoices.isFetching || state.distributors.isFetching,
		isLoadingStores: state.stores.isFetching,
		isLoadingInvoices: state.invoices.isFetching,
		isLoadingDistributors: state.distributors.isFetching,
		invoices: state.invoices.invoices,
		stores: state.stores.stores,
		distributors: state.distributors.distributors,
		calculations: state.calculations.calculationsById,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		fetchCalculations,
		fetchStores,
		fetchDistributors,
		fetchInvoices,
		updateItem,
		createItem,
		deleteItem,
		processCalculation,
		deleteCalculation,
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Calculations);
