import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Button, Form, Input, Row, Col } from 'antd';

const FormItem = Form.Item;
const { TextArea } = Input;

class Create extends Component {
	constructor(props) {
		super(props);
		this.state = {
			phones: [0],
			lastIndex: 0,
		};

		this.handleCancel = this.handleCancel.bind(this);
		this.handleSave = this.handleSave.bind(this);
	}

	componentWillReceiveProps(props) {
		if (props.distributor && props.distributor.phones.length !== this.state.lastIndex) {
			this.setState({
				phones: [...Array(props.distributor.phones.length).keys()],
				lastIndex: props.distributor.phones.length,
			});
		}
	}

	handleCancel() {
		this.props.form.resetFields();
		this.setState({ phones: [0], lastIndex: 0 });
		this.props.handleCancel();
	}

	handleSave() {
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const data = {
					name: values.name,
					email: values.email,
					website: values.website,
					address: values.address,
					notes: values.notes,
				};

				const phones = [];
				this.state.phones.forEach((phoneIndex) => {
					phones.push({
						name: values[`phones-name-${phoneIndex}`],
						role: values[`phones-role-${phoneIndex}`],
						number: values[`phones-phone-${phoneIndex}`],
					});
				});

				data.phones = phones;

				this.props.handleSave({ ...data, ...{ id: this.props.distributor ? this.props.distributor.id : undefined } })
					.then(() => {
						this.setState({ phones: [0] });
						this.props.form.resetFields();
					});
			}
		});
	}

	render() {
		const formItemLayout = {
			labelCol: {
				xs: { span: 24 },
				sm: { span: 6 },
			},
			wrapperCol: {
				xs: { span: 24 },
				sm: { span: 14 },
			},
		};

		const { getFieldDecorator } = this.props.form;


		return (<Modal
			width={700}
			visible={this.props.visible}
			title="Dodaj Distributera"
			onCancel={this.handleCancel}
			footer={[
				<Button key="cancel" onClick={this.handleCancel}>Poništi</Button>,
				<Button key="save" type="primary" loading={this.props.isCreating} onClick={this.handleSave}>
					Sačuvaj
				</Button>,
			]}
		>
			<Form>
				<FormItem
					{...formItemLayout}
					label="Naziv"
					hasFeedback
				>
					{getFieldDecorator('name', {
						rules: [{
							required: true, message: 'Naziv distributera je obavezan!',
						}],
					})(
						<Input />
					)}
				</FormItem>
				<FormItem
					{...formItemLayout}
					label="E-Mail"
					hasFeedback
				>
					{getFieldDecorator('email', {
						rules: [{
							type: 'email', message: 'E-Mail nije ispravan!',
						}],
					})(
						<Input />
					)}
				</FormItem>
				<FormItem
					{...formItemLayout}
					label="Web Sajt"
					hasFeedback
				>
					{getFieldDecorator('website', {})(
						<Input />
					)}
				</FormItem>
				<FormItem
					{...formItemLayout}
					label="Adresa"
					hasFeedback
				>
					{getFieldDecorator('address', {})(
						<TextArea />
					)}
				</FormItem>
				<FormItem
					{...formItemLayout}
					label="Telefoni"
					hasFeedback
				>
					{this.state.phones.map(index => (
						<Row
							key={index}
							style={{ marginBottom: '10px' }}
							gutter={16}
						>
							<Col span={7}>
								<FormItem
									hasFeedback
								>
									{getFieldDecorator(`phones-name-${index}`, {
										rules: [{
											required: true, message: 'Ime kontakta je obavezno!',
										}],
									})(
										<Input
											placeholder="Ime"
										/>
									)}
								</FormItem>
							</Col>
							<Col span={7}>
								<FormItem
									hasFeedback
								>
									{getFieldDecorator(`phones-role-${index}`, {})(
										<Input
											placeholder="Uloga"
										/>
									)}
								</FormItem>
							</Col>
							<Col span={7}>
								<FormItem
									hasFeedback
								>
									{getFieldDecorator(`phones-phone-${index}`, {
										rules: [{
											required: true, message: 'Telefon kontakta je obavezan!',
										}],
									})(
										<Input
											placeholder="Telefon"
										/>
									)}
								</FormItem>
							</Col>
							<Col span={3}>
								<Button
									onClick={() => {
										const copy = this.state.phones.slice();
										copy.splice(copy.indexOf(index), 1);
										this.setState({
											phones: copy,
										});
									}}
									icon="delete"
								/>
							</Col>
						</Row>))}
					<Button
						size="default"
						icon="plus"
						onClick={() => this.setState(
							{
								phones: [...this.state.phones, this.state.lastIndex + 1],
								lastIndex: this.state.lastIndex + 1,
							}
						)}
					>
						Dodaj telefon
					</Button>
				</FormItem>
				<FormItem
					{...formItemLayout}
					label="Napomene"
					hasFeedback
				>
					{getFieldDecorator('notes', {})(
						<TextArea />
					)}
				</FormItem>
			</Form>
		</Modal>);
	}
}

Create.propTypes = {
	visible: PropTypes.bool.isRequired,
	isCreating: PropTypes.bool.isRequired,
	handleCancel: PropTypes.func.isRequired,
	handleSave: PropTypes.func.isRequired,
	distributor: PropTypes.shape({
		id: PropTypes.number,
		phones: PropTypes.arrayOf(
			PropTypes.shape({})
		),
	}),
};

Create.defaultProps = {
	distributor: null,
};

function mapStateToProps(state) {
	return {
		isCreating: state.distributors.isCreating,
	};
}

function mapPropsToFields(props) {
	if (!props.distributor) {
		return {};
	}

	const phones = {};

	props.distributor.phones.forEach((phone, index) => {
		phones[`phones-name-${index}`] = Form.createFormField({ value: phone.name });
		phones[`phones-role-${index}`] = Form.createFormField({ value: phone.role });
		phones[`phones-phone-${index}`] = Form.createFormField({ value: phone.number });
	}
	);

	return {
		name: Form.createFormField({
			value: props.distributor.name,
		}),
		email: Form.createFormField({
			value: props.distributor.email,
		}),
		website: Form.createFormField({
			value: props.distributor.website,
		}),
		address: Form.createFormField({
			value: props.distributor.address,
		}),
		...phones,
		notes: Form.createFormField({
			value: props.distributor.notes,
		}),
	};
}

export default connect(mapStateToProps)(Form.create({ mapPropsToFields })(Create));
