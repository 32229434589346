
import _ from 'lodash';
import crudReducer from './crudReducer';

export default crudReducer('calculation', (state, action) => {
	switch (action.type) {
	case 'CALCULATION_UPDATE_ITEM_SUCCESS': {
		return {
			...state,
			calculationsById: {
				...state.calculationsById,
				[action.payload.data.calculationId]: {
					...state.calculationsById[action.payload.data.calculationId],
					calculationItems: [
						...state.calculationsById[action.payload.data.calculationId].calculationItems.map((item) => {
							if (item.id === action.payload.data.id) {
								return action.payload.data;
							}
							return item;
						}),
					],
				},
			},
		};
	}
	case 'CALCULATION_CREATE_ITEM_SUCCESS': {
		return {
			...state,
			calculationsById: {
				...state.calculationsById,
				[action.payload.data.calculationId]: {
					...state.calculationsById[action.payload.data.calculationId],
					calculationItems: [
						...state.calculationsById[action.payload.data.calculationId].calculationItems,
						action.payload.data,
					],
				},
			},
		};
	}
	case 'CALCULATION_DELETE_ITEM_SUCCESS': {
		const id = action.meta.previousAction.payload.request.data.id;
		const calculationId = action.meta.previousAction.payload.request.data.calculationId;

		return {
			...state,
			calculationsById: {
				...state.calculationsById,
				[calculationId]: {
					...state.calculationsById[calculationId],
					calculationItems: [
						...state.calculationsById[calculationId].calculationItems
							.filter(item => item.id !== id)],
				},
			},
		};
	}
	case 'CALCULATION_PROCESS_SUCCESS': {
		return {
			...state,
			calculationsById: {
				...state.calculationsById,
				[action.payload.data.id]: action.payload.data,
			},
		};
	}
	default: {
		return state;
	}
	}
});

