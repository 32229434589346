import React from 'react';
import {
	BrowserRouter as Router,
	Route,
} from 'react-router-dom';

import createHistory from 'history/createBrowserHistory';
import { PersistGate } from 'redux-persist/integration/react';
import makeInspectable from 'mobx-devtools-mst';

import { ConnectedRouter, routerMiddleware } from 'react-router-redux';
import { Provider } from 'react-redux';
import { Provider as MobXProvider } from 'mobx-react';

import axios from 'axios';
import { LocaleProvider } from 'antd';

import moment from 'moment';

import srRS from 'antd/lib/locale-provider/sr_RS';
import 'moment/locale/sr';
import { hot } from 'react-hot-loader';

import configureStore from './store';

import Login from './modules/Login';
import Application from './modules/Application';
import withAuth from './hocs/withAuth';

import { returnToApp } from './actions/auth';

import mstStore from './stores';

import config from './config.json';

import './App.css';


// Create a history of your choosing (we're using a browser history in this case)
const history = createHistory();

// Build the middleware for intercepting and dispatching navigation actions
const middleware = routerMiddleware(history);

const { store, persistor } = configureStore({}, history, middleware);

axios.defaults.baseURL = config.api;

moment.locale('sr');

store.dispatch(returnToApp());
makeInspectable(store);

function App() {
	return (
		<LocaleProvider locale={srRS}>
			<MobXProvider
				store={mstStore}
			>
				<Provider store={store}>
					{/* <PersistGate loading={null} persistor={persistor}> */}

					<ConnectedRouter history={history}>
						<div>
							<Route exact path="/" component={withAuth(Login)} />
							<Route path="/app" component={withAuth(Application, true)} />
						</div>
					</ConnectedRouter>
					{/* </PersistGate> */}
				</Provider>
			</MobXProvider>
		</LocaleProvider>
	);
}

export default hot(module)(App);
