
import _ from 'lodash';
import crudReducer from './crudReducer';

export default crudReducer('pricechange', (state, action) => {
	switch (action.type) {
	case 'PRICECHANGE_UPDATE_ITEM_SUCCESS': {
		return {
			...state,
			pricechangesById: {
				...state.pricechangesById,
				[action.payload.data.pricechangeId]: {
					...state.pricechangesById[action.payload.data.pricechangeId],
					pricechangeItems: [
						...state.pricechangesById[action.payload.data.pricechangeId].pricechangeItems.map((item) => {
							if (item.id === action.payload.data.id) {
								return action.payload.data;
							}
							return item;
						}),
					],
				},
			},
		};
	}
	case 'PRICECHANGE_CREATE_ITEM_SUCCESS': {
		return {
			...state,
			pricechangesById: {
				...state.pricechangesById,
				[action.payload.data.pricechangeId]: {
					...state.pricechangesById[action.payload.data.pricechangeId],
					pricechangeItems: [
						...state.pricechangesById[action.payload.data.pricechangeId].pricechangeItems,
						action.payload.data,
					],
				},
			},
		};
	}
	case 'PRICECHANGE_DELETE_ITEM_SUCCESS': {
		const id = action.meta.previousAction.payload.request.data.id;
		const pricechangeId = action.meta.previousAction.payload.request.data.pricechangeId;

		return {
			...state,
			pricechangesById: {
				...state.pricechangesById,
				[pricechangeId]: {
					...state.pricechangesById[pricechangeId],
					pricechangeItems: [
						...state.pricechangesById[pricechangeId].pricechangeItems
							.filter(item => item.id !== id)],
				},
			},
		};
	}
	case 'PRICECHANGE_PROCESS_SUCCESS': {
		return {
			...state,
			pricechangesById: {
				...state.pricechangesById,
				[action.payload.data.id]: action.payload.data,
			},
		};
	}
	default: {
		return state;
	}
	}
});

