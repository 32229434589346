import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Layout, Button, Table, Tag, Popconfirm } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import shortid from 'shortid';

import { fetchDistributors, createDistributor, deleteDistributor, updateDistributor } from '../../../actions/distributors';
import Create from './Create';

const { Header, Content } = Layout;

// TODO: permissions


class Distributors extends Component {
	constructor(props) {
		super(props);

		this.state = {
			createVisible: false,
			editDistributor: null,
		};

		this.columns = [
			{
				title: 'Naziv',
				dataIndex: 'name',
				key: 'name',
			},
			{
				title: 'E-Mail',
				dataIndex: 'email',
				key: 'email',
			},
			{
				title: 'Web Sajt',
				dataIndex: 'website',
				key: 'website',
			},
			{
				title: 'Adresa',
				dataIndex: 'address',
				key: 'address',
			},
			{
				title: 'Telefoni',
				dataIndex: 'phones',
				key: 'phones',
				render: (text, record) => (<div>
					{(record.phones || []).map(phone => (<Tag
						key={shortid.generate()}
					>
						<strong>{phone.name} {`${phone.role ? `(${phone.role})` : ''}`}:</strong> {phone.number}
					</Tag>))}
				</div>),
			},
			{
				title: 'Akcije',
				key: 'actions',
				render: (text, record) => (<Button.Group>
					<Button
						onClick={() => {
							this.setState({
								editDistributor: record,
								createVisible: true,
							});
						}}
						icon="edit"
					/>
					<Popconfirm
						placement="topRight"
						title="Da li ste sigurni da želite da obrišete ovog distributera?"
						onConfirm={() => {
							this.props.deleteDistributor(record.id);
						}}
						okText="Da"
						cancelText="Ne"
					>
						<Button icon="delete" />
					</Popconfirm>

				</Button.Group>),
			},
		];
	}

	componentDidMount() {
		this.props.fetchDistributors();
	}
	render() {
		return (<div>
			<Create
				distributor={this.state.editDistributor}
				visible={this.state.createVisible}
				handleCancel={() => this.setState({ createVisible: false })}
				handleSave={(data) => {
					if (data.id) {
						return this.props.updateDistributor(data)
							.then(() => this.setState({ createVisible: false }));
					}

					return this.props.createDistributor(data)
						.then(() => this.setState({ createVisible: false }));
				}
				}
			/>
			<Header>
				<Button
					type="primary"
					icon="plus"
					onClick={() => this.setState({ createVisible: true })}
				>
					Dodaj Distributera
				</Button>
			</Header>
			<Content>
				<Table
					loading={this.props.isLoading}
					rowKey="id"
					columns={this.columns}
					dataSource={this.props.distributors}
				/>
			</Content>
		</div>);
	}
}

Distributors.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	fetchDistributors: PropTypes.func.isRequired,
	createDistributor: PropTypes.func.isRequired,
	updateDistributor: PropTypes.func.isRequired,
	deleteDistributor: PropTypes.func.isRequired,
	distributors: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
		})
	).isRequired,
};

function mapStateToProps(state) {
	return {
		isLoading: state.distributors.isLoading || state.distributors.isDeleting,
		distributors: state.distributors.distributors,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ fetchDistributors, createDistributor, deleteDistributor, updateDistributor }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Distributors);
