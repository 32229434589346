import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	Col, Row, Modal, Button, Form, AutoComplete, Input, Select, DatePicker, Alert, Table, Drawer, Typography,
} from 'antd';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import moment from 'moment';

import { createProduct, fetchProducts, updateProduct } from '../../../../../actions/products';
import { createSaleItem, updateSaleItem } from '../../../../../actions/sales';

const FormItem = Form.Item;

const EditableCell = ({ editable, value, onChange }) => (
	<div>
		{editable
			? <Input style={{ margin: '-5px 0' }} value={value} onChange={e => onChange(e.target.value)} />
			: value
		}
	</div>
);

class ProcessSale extends Component {
	render() {
		if (!this.props.visible) {
			return null;
		}

		return (
			<Drawer

				width={900}
				visible={this.props.visible}
				title="Knjiženje prodaje"
				onCancel={() => {}}
				footer={[
					<Button key="cancel" onClick={this.handleCancel}>Poništi</Button>,
					<Button key="save" type="primary" loading={this.props.isCreating} onClick={this.handleSave}>
						Sačuvaj
					</Button>,
				]}
			>
				<Table
					dataSource={this.props.entity.saleItems}
					size="medium"
					bordered
					columns={[
						{
							key: 'product.sku',
							title: 'Šifra',
							dataIndex: 'product.sku',
						},
						{
							key: 'product.name',
							title: 'Name',
							dataIndex: 'product.name',
							render: (text, record) => (
								<span>
									{text}<br />
									{(() => {
										if (!record.product.price) {
											return (
												<Typography.Text
													type="danger"
												>
													Proizvod nema cenu
												</Typography.Text>
											);
										}
										if ((
											record.grossPrice / record.quantity !== record.product.price
											&& record.grossPrice / record.quantity !== record.product.price + 0.01
										)) {
											return (
												<Typography.Text
													type="warning"
												>
												Cena proizvoda se razlikuje
												</Typography.Text>
											);
										}

										return null;
									})()}
								</span>
							),
						},
						{
							key: 'corrections',
							title: 'Korekcije',
							render: record => (() => {
								if (!record.product.price) {
									return (
										<Select defaultValue="select">
											<Select.Option value="select" key="select">
												Izaberite način korekcije
											</Select.Option>
											<Select.Option key="add" value="add">
												Dodaj proizvod u inventar
											</Select.Option>
										</Select>
									);
								}
								if ((
									record.grossPrice / record.quantity !== record.product.price
										&& record.grossPrice / record.quantity !== record.product.price + 0.01
								)) {
									return (
										<Select defaultValue="select">
											<Select.Option value="select" key="select">
												Izaberite način korekcije
											</Select.Option>
											<Select.Option key="priceChange" value="priceChange">
												Kreiraj nivelaciju
											</Select.Option>
											{record.grossPrice / record.quantity < record.product.price && (
												<Select.Option key="discount" value="discount">
													Popust na kasi
												</Select.Option>
											)}

										</Select>
									);
								}

								// TODO: Dodaj u postojeću neproknjiženu nivelaciju
								return null;
							})(),
						},
					]}
				/>
			</Drawer>
		);
	}
}

ProcessSale.propTypes = {
	visible: PropTypes.bool.isRequired,
};

ProcessSale.defaultProps = {

};

function mapPropsToFields(props) {
	if (!props.entity) {
		return {};
	}

	return {
		..._.mapValues(props.entity, entity => (Form.createFormField({ value: entity }))),
		product: {
			..._.mapValues(props.entity.product, product => (Form.createFormField({ value: product }))),
		},
	};
}

export default Form.create({ mapPropsToFields })(ProcessSale);
