import { handleActions } from 'redux-actions';

import {
	FETCH_BRANDS_ATTEMPT,
	FETCH_BRANDS_FAILED,
	FETCH_BRANDS_SUCCESS,
	CREATE_BRANDS_ATTEMPT,
	CREATE_BRANDS_FAILED,
	CREATE_BRANDS_SUCCESS,
	UPDATE_BRANDS_ATTEMPT,
	UPDATE_BRANDS_FAILED,
	UPDATE_BRANDS_SUCCESS,
	DELETE_BRANDS_ATTEMPT,
	DELETE_BRANDS_FAILED,
	DELETE_BRANDS_SUCCESS,
} from '../actions/types';

const defaultState = {
	isFetching: false,
	isCreating: false,
	isDeleting: false,
	error: null,
	brands: [],
};

const brands = handleActions({
	[FETCH_BRANDS_ATTEMPT]: state => ({
		...state,
		isFetching: true,
	}),
	[FETCH_BRANDS_FAILED]: (state, action) => ({
		...state,
		isFetching: false,
		error: action.payload,
	}),
	[FETCH_BRANDS_SUCCESS]: (state, action) => ({
		...state,
		isFetching: false,
		error: null,
		brands: action.payload,
	}),
	[CREATE_BRANDS_ATTEMPT]: state => ({
		...state,
		isCreating: true,
	}),
	[CREATE_BRANDS_FAILED]: (state, action) => ({
		...state,
		isCreating: false,
		error: action.payload,
	}),
	[CREATE_BRANDS_SUCCESS]: (state, action) => ({
		...state,
		isCreating: false,
		error: null,
		brands: [...state.brands, action.payload],
	}),
	[UPDATE_BRANDS_ATTEMPT]: state => ({
		...state,
		isCreating: true,
	}),
	[UPDATE_BRANDS_FAILED]: (state, action) => ({
		...state,
		isCreating: false,
		error: action.payload,
	}),
	[UPDATE_BRANDS_SUCCESS]: (state, action) => ({
		...state,
		isCreating: false,
		error: null,
		brands: [
			...state.brands.map(
				brand => (brand.id === action.payload.id ? action.payload : brand)
			)],
	}),
	[DELETE_BRANDS_ATTEMPT]: state => ({
		...state,
		isDeleting: true,
	}),
	[DELETE_BRANDS_FAILED]: (state, action) => ({
		...state,
		isDeleting: false,
		error: action.payload,
	}),
	[DELETE_BRANDS_SUCCESS]: (state, action) => ({
		...state,
		isDeleting: false,
		error: null,
		brands: [...state.brands.filter(brand => brand.id !== action.payload)],
	}),

}, defaultState);

export default brands;
