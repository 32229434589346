import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Layout from 'antd/lib/layout';
import {
	Card, Form, Icon, Input, Button, Checkbox,
} from 'antd';
import { connect } from 'react-redux';

import { login } from '../../actions/auth';

import './style.css';

const { Content, Footer } = Layout;
const FormItem = Form.Item;
console.log(Content);

class Login extends Component {
	constructor(props) {
		super(props);

		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleSubmit(e) {
		// this.props.dispatch(login('ads', 'asd'));
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				this.props.dispatch(login(values.email, values.password));
			}
		});
		// return false;
	}

	render() {
		const { getFieldDecorator } = this.props.form;
		return (
			<Layout className="bo-login">
				<Content className="bo-login-content">
					<Card
						bordered={false}
						className="bo-login-card"
					>
						<Form onSubmit={this.handleSubmit} className="login-form">
							<FormItem>
								{getFieldDecorator('email', {
									rules: [{ required: true, message: 'Unesite E-Mail!' }],
								})(
									<Input prefix={<Icon type="mail" style={{ fontSize: 13 }} />} placeholder="E-Mail" />
								)}
							</FormItem>
							<FormItem>
								{getFieldDecorator('password', {
									rules: [{ required: true, message: 'Unesite lozinku!' }],
								})(
									<Input prefix={<Icon type="lock" style={{ fontSize: 13 }} />} type="password" placeholder="Lozinka" />
								)}
							</FormItem>
							<FormItem>
								{getFieldDecorator('remember', {
									valuePropName: 'checked',
									initialValue: true,
								})(
									<Checkbox className="bo-login-remember">Zapamti me</Checkbox>
								)}
								<a className="bo-login-forgot" href="">Zaboravio/la sam lozinku</a>

								<Button loading={this.props.isLoading} type="primary" htmlType="submit" className="bo-login-button">Prijavi se</Button>
								Ili
								<a href="">se učlani!</a>
							</FormItem>
						</Form>
					</Card>
				</Content>

				<Footer className="bo-login-footer">Copyright &copy; 2017 Pet Shop Moja Mačkica</Footer>
			</Layout>
		);
	}
}

Login.propTypes = {
	dispatch: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
	return {
		isLoading: state.auth.isLoading,
	};
}

export default connect(mapStateToProps)(Form.create()(Login));
