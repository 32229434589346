
import _ from 'lodash';
import crudReducer from './crudReducer';

export default crudReducer('sale', (state, action) => {
	switch (action.type) {
	case 'CALCULATION_PROCESS_SUCCESS': {
		return {
			...state,
			salessById: {
				...state.salesById,
				[action.payload.data.id]: action.payload.data,
			},
		};
	}
	default: {
		return state;
	}
	}
});

